import { useState, useEffect, useCallback } from "react";
import { getPatientsList } from "../../service/patientService";

export const usePatients = (initialPage = 1, initialPageLimit = 10) => {
    const [patients, setPatients] = useState({ items: [], totalCount: 0, myPatientCount: 0, searchCount: 0 });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(initialPage);
    const [pageLimit, setPageLimit] = useState(initialPageLimit);
    const [searchData, setSearchData] = useState({
        listType: "ALL",
        searchName: "",
        searchPhone: "",
        searchBirth: "",
    });

    const fetchPatients = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await getPatientsList({ page, pageLimit, searchData });
            setPatients(data);
        } catch (err) {
            setError(err instanceof Error ? err : new Error("An error occurred"));
        } finally {
            setLoading(false);
        }
    }, [page, pageLimit, searchData]);

    useEffect(() => {
        fetchPatients();
    }, [fetchPatients]);

    const updatePage = (newPage) => setPage(newPage);
    const updatePageLimit = (newSize) => setPageLimit(newSize);
    const updateSearchData = (newSearchData) => setSearchData(newSearchData);

    return {
        patients,
        loading,
        error,
        page,
        pageLimit,
        searchData,
        updatePage,
        updatePageLimit,
        updateSearchData,
        refetch: fetchPatients,
    };
};
