import { instance } from "apis";

/**
 * 주어진 API 엔드포인트로 HTTP 요청을 보내 데이터를 가져옵니다.
 *@example GET - fetchData({ method: 'GET', endpoint: '/users', payload: { page: 1, limit: 10 } });
 *@example POST - fetchData({ method: 'POST', endpoint: '/users', payload: { name: 'John', age: 30 } });
 *@example PUT - fetchData({ method: 'PUT', endpoint: '/users/1', payload: { name: 'John Updated' } });
 *@example DELETE - fetchData({ method: 'DELETE', endpoint: '/users/1' });
 
 * @function fetchData
 * @param {Object} options - 요청 옵션
 * @param {string} options.method - HTTP 메서드 (GET, POST, PUT, DELETE 등)
 * @param {string} options.endpoint - 요청을 보낼 API 엔드포인트
 * @param {Object} [options.payload] - 요청에 포함될 데이터 (GET의 경우 쿼리 파라미터, 나머지는 요청 바디)
 * @returns {Promise<Object>} - API로부터의 응답을 반환합니다.
 */
const fetchData = async ({ method, endpoint, payload = null }) => {
    const config = {
        method: method.toUpperCase(),
        url: endpoint,
    };

    if (payload) {
        if (method.toUpperCase() === "GET") {
            config.params = payload;
        } else {
            config.data = payload;
        }
    }

    try {
        const response = await instance(config);
        return response;
    } catch (error) {
        console.error("Error in fetchData:", error);
        throw error;
    }
};

export default fetchData;
