import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import { Form, Button, Row, Col } from "react-bootstrap";
import { faKey, faUser, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { USER_ROLE_LIST } from "constants/userRole";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import UserCertificationModal from "./UserCertificationModal";
import { ID_PLACEHOLDER, PASSWORD_PLACEHOLDER } from "constants/placeholder";
import checkIsEmpty from "utils/checkIsEmpty";
import { ERROR_ID_PASSWORD_EMPTY } from "constants/errorMessage";
import { toast } from "sonner";

const FormContainer = () => {
    const navigate = useNavigate();
    const defaultRoleIndex = parseInt(sessionStorage.getItem("roleIndex"), 10) || 1;
    const [userId, setUserId] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [userRole, setUserRole] = useState(defaultRoleIndex ?? 1);
    const [uuid, setUuid] = useState();
    const hospitalInfo = JSON.parse(sessionStorage.getItem("infHsp") || "{}"); // 서브 도메인 조회

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [capsLockOn, setCapsLockOn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordFocused, setPasswordFocused] = useState(false);

    const handleCapsLock = useCallback((event) => {
        if (event instanceof KeyboardEvent) {
            setCapsLockOn(event.getModifierState("CapsLock"));
        }
    }, []);

    const handlePasswordFocus = () => {
        setPasswordFocused(true);
        handleCapsLock({ getModifierState: () => false });
    };

    const handlePasswordBlur = () => {
        setPasswordFocused(false);
    };

    useEffect(() => {
        if (passwordFocused) {
            window.addEventListener("keydown", handleCapsLock);
            window.addEventListener("keyup", handleCapsLock);
        }

        return () => {
            window.removeEventListener("keydown", handleCapsLock);
            window.removeEventListener("keyup", handleCapsLock);
        };
    }, [handleCapsLock, passwordFocused]);

    //권한선택 키보드 입력 1,2,3으로 선택가능하게 추가(커서가 Input안에 있으면 예외처리)
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (document.activeElement.tagName !== "INPUT" && document.activeElement.tagName !== "TEXTAREA") {
                const keyToIndexMap = { 1: 0, 2: 1, 3: 2 };
                if (keyToIndexMap[event.key] !== undefined) {
                    const index = keyToIndexMap[event.key];
                    setUserRole(index);
                    sessionStorage.setItem("roleIndex", index);
                }
            }
        };

        window.addEventListener("keydown", handleKeyPress);
        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, []);

    useEffect(() => {
        const savedRoleIndex = sessionStorage.getItem("roleIndex");
        if (savedRoleIndex !== null) {
            setUserRole(parseInt(savedRoleIndex, 10));
        }
    }, []);

    const { mutate: loginMutate } = useMutation({
        mutationFn: (requestData) => fetchData("POST", "/v1/auth/logIn", requestData),
        onSuccess: (response) => {
            if (response.result === "pass") {
                if (userRole === 0) {
                    const token = response.data.token;
                    sessionStorage.setItem("token", token);
                    navigate("/operator-setting");
                } else {
                    setIsModalOpen(true);
                    setUuid(response.data.token);
                }
            }
        },
        onError: ({ response }) => {
            if (response.status === 404) {
                toast.error("로그인에 실패했습니다. 다시 시도해주세요.");
            } else if (response.status === 401 || response.status === 500) {
                toast.error("아이디/비밀번호를 확인해주세요.");
            }
        },
    });

    const handleSendLoginRequest = () => loginMutate({ userId, userPassword, role: userRole, subDomain: hospitalInfo.domain });

    const onSubmit = (e) => {
        e.preventDefault();
        if (!checkIsEmpty(userId, userPassword)) {
            toast.error(ERROR_ID_PASSWORD_EMPTY);
            return;
        }
        handleSendLoginRequest();
    };

    const handleRoleChange = (index) => {
        setUserRole(index);
        sessionStorage.setItem("roleIndex", index);
    };

    return (
        <>
            <Form onSubmit={onSubmit}>
                <Row className="g-4 mb-5">
                    <Col xs={12}>
                        <Row className="gx-lg-7">
                            {USER_ROLE_LIST.map((role, index) => (
                                <Col xs={4} md="auto" key={index}>
                                    <Form.Check type="radio" id={`radio-role-${index}`}>
                                        <Form.Check.Input
                                            value={index}
                                            type="radio"
                                            onChange={() => handleRoleChange(index)}
                                            checked={userRole === index}
                                        />
                                        <Form.Check.Label htmlFor={`radio-role-${index}`} className="fs-8 text-body">
                                            {role}
                                        </Form.Check.Label>
                                    </Form.Check>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
                <Form.Group className="mb-3 text-start">
                    <Form.Label htmlFor="id" className="fs-8">
                        아이디
                    </Form.Label>
                    <div className="form-icon-container">
                        <Form.Control
                            id="id"
                            type="text"
                            className="form-icon-input"
                            placeholder={ID_PLACEHOLDER}
                            value={userId}
                            onChange={(e) => setUserId(e.target.value)}
                        />
                        <FontAwesomeIcon icon={faUser} className="text-body fs-9 form-icon" />
                    </div>
                </Form.Group>
                <Form.Group className="mb-3 text-start">
                    <Form.Label htmlFor="password" className="fs-8">
                        비밀번호
                    </Form.Label>
                    <div className="form-icon-container position-relative">
                        <Form.Control
                            id="password"
                            type={showPassword ? "text" : "password"}
                            className="form-icon-input"
                            placeholder={PASSWORD_PLACEHOLDER}
                            onChange={(e) => setUserPassword(e.target.value)}
                            onFocus={handlePasswordFocus}
                            onBlur={handlePasswordBlur}
                        />
                        <FontAwesomeIcon icon={faKey} className="text-body fs-9 form-icon" />
                        <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            className="position-absolute top-50 end-0 translate-middle-y me-2 cursor-pointer"
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </div>
                    {passwordFocused && capsLockOn && <Form.Text className="text-warning">Caps Lock이 켜져 있습니다.</Form.Text>}
                </Form.Group>

                <Button variant="primary" className="w-100 mb-3" type="submit">
                    로그인 하기
                </Button>
            </Form>

            <UserCertificationModal
                isModalOpen={isModalOpen}
                handleCloseModal={() => setIsModalOpen(false)}
                handleSendLoginRequest={handleSendLoginRequest}
                uuid={uuid}
                role={userRole}
            />
        </>
    );
};

export default FormContainer;
