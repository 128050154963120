import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import { CONFIRM_ID, DULICATED_ID } from "constants/errorMessage";
import { toast } from "sonner";
import useConfirmDialog from "hooks/useConfirmDialog";
import { icon } from "leaflet";

const useCheckDuplicateId = (userId, setIsValidId, idRef, passwordRef) => {
    const showConfirmDialog = useConfirmDialog();
    const { mutate: checkDuplicateIdMutate } = useMutation({
        mutationFn: (id) => fetchData("POST", "/v1/setting/check/userId", { userId: id }),
        onSuccess: (data) => {
            if (data.result === "pass") {
                showConfirmDialog(
                    { text: `${userId} ${CONFIRM_ID}`, icon: "question" },
                    () => {
                        setIsValidId(true);
                        passwordRef.current.focus();
                    },
                    () => {
                        idRef.current.focus();
                    },
                );
            }
        },
        onError: ({ response }) => {
            if (response.status === 409) {
                toast.error(`${userId} ${DULICATED_ID}`);
                return;
            }
        },
    });

    return checkDuplicateIdMutate;
};

export default useCheckDuplicateId;
