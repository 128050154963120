import MuiDatePicker from "components/base/MuiDatePicker";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { currentDate } from "store/atoms";
import DailySquat from "./DailySquat";
import DailyStretching from "./DailyStretching";
import DailySummary from "./DailySummary";
import DailyWalking from "./DailyWalking";
import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import calculateEndDate from "utils/calculateEndDate";

const DailyWorkoutResult = ({ searchDate }) => {
    const [workoutDate, setWorkoutDate] = useState(sessionStorage.getItem("workoutDate"));
    const [maxDate, setMaxDate] = useState(calculateEndDate(searchDate, "12"));
    const patientUID = sessionStorage.getItem("patientUID");

    const {
        mutate: dailyWorkoutMutate,
        data: dailyWorkoutData,
        isLoading,
        isError,
    } = useMutation({
        mutationFn: ({ patientUID, workoutDate: searchDate }) => fetchData("POST", `/v1/monitoring/view/summary`, { patientUID, searchDate }),
    });

    useEffect(() => {
        if (patientUID && workoutDate) {
            dailyWorkoutMutate({ patientUID, workoutDate });
        }
    }, [patientUID, workoutDate, dailyWorkoutMutate]);

    return (
        <>
            <div className="w-50">
                <MuiDatePicker
                    onChange={(value) => {
                        setWorkoutDate(value);
                    }}
                    minDate={searchDate}
                    maxDate={maxDate}
                    value={workoutDate}
                />
            </div>
            {isLoading ? (
                <Row>
                    <Col md={12} style={{ height: "600px" }}>
                        <div justifyContent="around" className="h-100 d-flex justify-content-around p-2 mb-2">
                            <div className="d-flex align-items-lg-center mb-2">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        </div>
                    </Col>
                </Row>
            ) : isError ? (
                <div>Error occurred while fetching data</div>
            ) : !dailyWorkoutData?.data ? (
                <div justifyContent="around" className="h-100 d-flex justify-content-around p-2 mb-2">
                    <div className="d-flex align-items-lg-center mb-2">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                </div>
            ) : (
                <Row>
                    <Col md={12}>
                        <DailySummary summaryData={dailyWorkoutData?.data?.summary} />
                    </Col>
                    <Col md={12}>
                        <DailyStretching stretchingData={dailyWorkoutData?.data.stretching} />
                    </Col>
                    <Col md={12}>
                        <DailyWalking walkingData={dailyWorkoutData?.data.walking} />
                    </Col>
                    <Col md={12}>
                        <DailySquat squatData={dailyWorkoutData?.data.squat} />
                    </Col>
                </Row>
            )}
        </>
    );
};

export default DailyWorkoutResult;
