import { useQuery } from "@tanstack/react-query";
import fetchData from "apis/fetchData";

const useEttList = (patientUID) => {
    return useQuery({
        queryKey: ["ettList", patientUID],
        queryFn: async () => {
            return fetchData("POST", "/v1/management/ett/list", {
                patientUID: patientUID,
            });
        },
        enabled: !!patientUID,
        staleTime: 5 * 60 * 1000, // 5분
        cacheTime: 10 * 60 * 1000, // 10분
    });
};

export default useEttList;
