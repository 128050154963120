import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import { useEffect, useState } from "react";
import { Button as BootstrapButton, Col, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import ExistingStaffListModal from "../ExistingStaffListModal";
import OperatorInfo from "./OperatorInfo";
import StaffTable from "./StaffTable";

const OperatorDetail = () => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const operatorUUID = searchParams.get("id");
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { data: operatorDetail, mutate: operatorDetailMutate } = useMutation({
        mutationFn: (requestData) => fetchData("POST", "/v1/setting/operator/detail", requestData),
    });

    const { data: existingStaffList, mutate: getStaffListMutate } = useMutation({
        mutationFn: (requestData) => fetchData("POST", "/v1/setting/operator/staff/list", requestData),
    });

    useEffect(() => {
        operatorDetailMutate({ operatorUUID });
    }, [operatorDetailMutate, operatorUUID]);

    return (
        <>
            <div className="pb-5">
                <Row className="g-2">
                    <div className="mb-2">
                        <h2>운영자 정보</h2>
                    </div>
                    <hr className="bg-body-secondary mb-3" />
                </Row>
                <div className="order-1 order-xl-0 col-xl-12 col-12">
                    <div className="mb-4 shadow-none border overflow-visible card">
                        <div className="p-0 card-body">
                            <div className="p-4">
                                <Row className="g-2">
                                    <div className="mb-2">
                                        <h3>기본 정보</h3>
                                    </div>
                                    <hr className="bg-body-secondary mb-3" />
                                </Row>
                                <Row>
                                    <Col>
                                        <OperatorInfo operatorDetail={operatorDetail} operatorUUID={operatorUUID} />
                                    </Col>
                                </Row>
                                <div className="mb-1 mt-6">
                                    <h3>담당 실무자</h3>
                                </div>
                                <Row>
                                    <Col>
                                        <StaffTable
                                            operatorDetail={operatorDetail}
                                            operatorUUID={operatorUUID}
                                            operatorDetailMutate={operatorDetailMutate}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex justify-content-end gap-2 mt-3">
                                        <BootstrapButton
                                            variant="outline-secondary"
                                            onClick={() => {
                                                getStaffListMutate({ operatorUUID });
                                                setIsModalOpen(true);
                                            }}
                                        >
                                            기존 실무자 등록하기
                                        </BootstrapButton>
                                        <BootstrapButton onClick={() => navigate("/staff-setting/register")}>신규 실무자 등록하기</BootstrapButton>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ExistingStaffListModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                existingStaffList={existingStaffList?.data.staff}
                operatorUUID={operatorUUID}
                operatorDetailMutate={operatorDetailMutate}
            />
        </>
    );
};

export default OperatorDetail;
