import React from "react";
import { Table, Spinner } from "react-bootstrap";

const PatientList = ({ patients, loading, error, handleClickData }) => {
    return (
        <div className="scrollbar ms-n1 ps-1">
            <Table bordered hover className="phoenix-table fs-9 mb-0 border-top">
                <thead className="bg-body-highlight">
                    <tr>
                        <th className="text-center text-body-tertiary align-middle" style={{ width: "11%", minWidth: "55px" }}>
                            이름
                        </th>
                        <th className="text-center text-body-tertiary align-middle" style={{ width: "11%", minWidth: "100px" }}>
                            생년월일
                        </th>
                        <th className="text-center text-body-tertiary align-middle" style={{ width: "11%", minWidth: "100px" }}>
                            프로그램명
                        </th>
                        <th className="text-center text-body-tertiary align-middle" style={{ width: "11%", minWidth: "100px" }}>
                            1차 검사일
                        </th>
                        <th className="text-center text-body-tertiary align-middle" style={{ width: "11%", minWidth: "100px" }}>
                            2차 검사일
                        </th>
                        <th className="text-center text-body-tertiary align-middle" style={{ width: "11%", minWidth: "100px" }}>
                            3차 검사일
                        </th>
                        <th className="text-center text-body-tertiary align-middle" style={{ width: "11%", minWidth: "100px" }}>
                            발병일
                        </th>
                        <th className="text-center text-body-tertiary align-middle" style={{ width: "11%", minWidth: "100px" }}>
                            퇴원일(기준일)
                        </th>
                        {/* <th className="text-center text-body-tertiary align-middle" style={{ width: "11%", minWidth: "100px" }}>
                            재발병
                        </th> */}
                    </tr>
                </thead>
                <tbody>
                    {patients && patients.length > 0 ? (
                        patients.map((patient) => (
                            <tr key={patient.no} onClick={() => handleClickData(patient)} style={{ cursor: "pointer" }}>
                                {/* <td>{patient.no}</td> */}
                                <td className="text-center">{patient.name}</td>
                                <td className="text-center">{patient.birth}</td>
                                <td className="text-center">{patient.programName}</td>
                                <td className="text-center">
                                    {patient.firstDate !== null ? (patient.firstDate !== "submit" ? patient.firstDate : "-") : "-"}
                                </td>
                                <td className="text-center">
                                    {patient.secondDate !== null ? (patient.secondDate !== "submit" ? patient.secondDate : "-") : "-"}
                                </td>
                                <td className="text-center">
                                    {patient.thirdDate !== null ? (patient.thirdDate !== "submit" ? patient.thirdDate : "-") : "-"}
                                </td>
                                <td className="text-center">{patient.dateOccur !== null ? patient.dateOccur : "-"}</td>
                                <td className="text-center">{patient.dateBase !== null ? patient.dateBase : "-"}</td>
                                {/* <td className="text-center">{patient.reDate !== null ? patient.reDate : "-"}</td> */}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={9} className="text-center text-body-tertiary">
                                {error ? (
                                    "오류가 발생했습니다."
                                ) : loading ? (
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>
                                ) : (
                                    "조회된 검색결과가 없습니다."
                                )}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default PatientList;
