import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { Button as BootstrapButton, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ExistingStaffListModal from "../ExistingStaffListModal";
import HospitalInfoTable from "../HospitalInfoTable";
import StaffTable from "./StaffTable";

const StaffSetting = () => {
    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");
    const decoded = jwtDecode(token);
    const operatorUUID = decoded.uuid;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { data: staffInfo, mutate: staffInfoMutate } = useMutation({
        mutationFn: () => fetchData("GET", "/v1/setting/staff/list"),
    });

    const { data: operatorDetail, mutate: operatorDetailMutate } = useMutation({
        mutationFn: (requestData) => fetchData("POST", "/v1/setting/operator/detail", requestData),
    });

    const { data: existingStaffList, mutate: getStaffListMutate } = useMutation({
        mutationFn: (requestData) => fetchData("POST", "/v1/setting/operator/staff/list", requestData),
    });

    useEffect(() => {
        staffInfoMutate();
    }, [staffInfoMutate]);

    useEffect(() => {
        operatorDetailMutate({ operatorUUID });
    }, [operatorDetailMutate, operatorUUID]);

    return (
        <>
            <div className="pb-5">
                <Row className="g-2">
                    <div className="mb-2">
                        <h2>실무자 관리</h2>
                    </div>
                    <hr className="bg-body-secondary mb-3" />
                </Row>

                <div className="order-1 order-xl-0 col-xl-12 col-12">
                    <div className="mb-4 shadow-none border overflow-visible card">
                        <div className="p-0 card-body">
                            <div className="p-4">
                                {staffInfo && (
                                    <>
                                        <div className="mb-8">
                                            <HospitalInfoTable hospitalInfo={staffInfo.data.hospitalInfo} />
                                        </div>
                                        <Row className="g-2">
                                            <div className="mb-3">
                                                <h3>실무자</h3>
                                            </div>
                                        </Row>
                                        <StaffTable
                                            operatorDetail={operatorDetail}
                                            operatorUUID={operatorUUID}
                                            operatorDetailMutate={operatorDetailMutate}
                                        />
                                        <Row>
                                            <Col className="d-flex justify-content-end gap-2 mt-3">
                                                <BootstrapButton
                                                    variant="outline-secondary"
                                                    onClick={() => {
                                                        getStaffListMutate({ operatorUUID });
                                                        setIsModalOpen(true);
                                                    }}
                                                >
                                                    기존 실무자 등록하기
                                                </BootstrapButton>
                                                <BootstrapButton onClick={() => navigate("/staff-lower-setting/register")}>
                                                    신규 실무자 등록하기
                                                </BootstrapButton>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ExistingStaffListModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                existingStaffList={existingStaffList?.data.staff}
                operatorUUID={operatorUUID}
                operatorDetailMutate={operatorDetailMutate}
            />
        </>
    );
};

export default StaffSetting;
