import CounselingList from "components/Counseling/CounselingList/CounselingListIndex";
import CounselingModalFormIndex from "components/Counseling/CounselingModal/CounselingModalFormIndex";
import { OPTION_LIST } from "constants/cardiacTargetTypeList";
import useCounselingList from "hooks/counseling/useCounselingList";
import { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import CycleTab from "../../components/PatientMng/CycleTab";
import PatientInfoRow from "../../components/PatientMng/PatientInfoRow";
import { useEttData } from "../../hooks/counseling/useEttData";

const EttDetail = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const patientUID = sessionStorage.getItem("patientUID");
    const [showCounselingModal, setShowCounselingModal] = useState(false);

    // URL 파라미터에서 데이터 가져오기
    const cycle = searchParams.get("cycle") || "1";
    const [activeTab, setActiveTab] = useState(cycle);
    const programId = searchParams.get("programId") || "";
    const counselingType = "ett"; // 상담내역 모듈별 구분

    const { data: ettDataResponse, isLoading: isEttDataLoading, error: ettDataError } = useEttData(cycle, programId); // ett정보 조회
    const { patientInfo, firstRegister, cycleId, info, diseaseInfo, inspectInfo } = ettDataResponse?.data || {}; // ettData 객체 할당

    const { data: counselingList, isLoading: isCounselingLoading, refetch } = useCounselingList(patientUID, programId, counselingType, cycleId); // 상담 내역 조회

    // URL 파라미터에 cycle 값이 변경될때 텝메뉴 활성화
    useEffect(() => {
        const currentCycle = searchParams.get("cycle") || "1";
        setActiveTab(currentCycle);
    }, [searchParams]);

    // n차검사 탭메뉴 클릭
    const handleTabClick = (tabNumber, currentCycleId) => {
        setActiveTab(tabNumber);
        navigate(`/ett/detail?cycle=${tabNumber}&programId=${programId}`, { replace: true });
    };

    const handleOpenCounselingModal = () => setShowCounselingModal(true);
    const handleCloseCounselingModal = () => {
        setShowCounselingModal(false);
        refetch(); // 모달이 닫힐 때 상담 목록을 새로고침
    };

    if (isEttDataLoading)
        return (
            <div className="pb-5">
                <Row className="g-2">
                    <div className="mb-2">
                        <h2>ETT 기록</h2>
                    </div>
                    <hr className="bg-body-secondary mb-3" />
                </Row>
                <div className="p-3 rounded-1 position-absolute top-50 start-50 translate-middle">
                    <div>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                </div>
            </div>
        );
    if (!ettDataResponse.data) return <div>No data available</div>;

    return (
        <>
            <div className="pb-5">
                <Row className="g-2">
                    <div className="mb-2">
                        <h2>ETT 기록</h2>
                    </div>
                    <p className="text-body-tertiary lead">
                        운동부하 {cycle}차 검사{diseaseInfo.reDateBase === true ? <span className="text-danger">(재발병)</span> : ""}
                    </p>
                    <hr className="bg-body-secondary mb-3" />
                </Row>

                <div className="order-1 order-xl-0 col-xl-12 col-12">
                    <div className="mb-4 shadow-none border overflow-visible card">
                        <div className="p-0 card-body">
                            <div className="p-4">
                                <PatientInfoRow />

                                <CycleTab activeTab={activeTab} handleTabClick={handleTabClick} programId={programId} type="" />

                                <div className="p-6 pt-3">
                                    <div className="mb-3">
                                        <h4>기본정보</h4>
                                    </div>

                                    <Row className="justify-content-md-start p-2">
                                        <Col sm={6}>
                                            <Row>
                                                <Col sm={3} className="text-muted">
                                                    <h5 className="text-body-quaternary mb-0">담당의</h5>
                                                </Col>
                                                <Col sm={9}>
                                                    <h5 className="ms-4 mb-0">{info.doctorName}</h5>
                                                </Col>
                                                <hr
                                                    className="ms-3 mt-3"
                                                    style={{ borderStyle: "dashed", borderWidth: "1px 0 0 0 ", width: "95%" }}
                                                />
                                            </Row>
                                        </Col>
                                        <Col sm={6}>
                                            <Row>
                                                <Col sm={3} className="text-muted">
                                                    <h5 className="text-body-quaternary mb-0">키/몸무게</h5>
                                                </Col>
                                                <Col sm={9}>
                                                    <h5 className="mb-0">
                                                        {info.height}cm / {info.weight}kg
                                                    </h5>
                                                </Col>
                                                <hr
                                                    className="ms-3 mt-3"
                                                    style={{ borderStyle: "dashed", borderWidth: "1px 0 0 0 ", width: "95%" }}
                                                />
                                            </Row>
                                        </Col>
                                    </Row>

                                    <div className="mt-6 mb-3">
                                        <h4>질환정보</h4>
                                    </div>

                                    <Row className="justify-content-md-start p-2">
                                        <Col sm={6}>
                                            <Row>
                                                <Col sm={3} className="text-muted">
                                                    <h5 className="text-body-quaternary mb-0">재발병 여부</h5>
                                                </Col>
                                                <Col sm={9}>
                                                    <h5 className="ms-4 mb-0">{diseaseInfo.reDateBase === true ? "해당있음" : "해당없음"}</h5>
                                                </Col>
                                                <hr
                                                    className="ms-3 mt-3"
                                                    style={{ borderStyle: "dashed", borderWidth: "1px 0 0 0 ", width: "95%" }}
                                                />
                                            </Row>
                                        </Col>
                                        <Col sm={6}></Col>
                                    </Row>

                                    <Row className="justify-content-md-start p-2">
                                        <Col sm={6}>
                                            <Row>
                                                <Col sm={3} className="text-muted">
                                                    <h5 className="text-body-quaternary mb-0">심장 질환명</h5>
                                                </Col>
                                                <Col sm={9}>
                                                    <h5 className="ms-4 mb-0">{diseaseInfo.diseaseName !== null ? diseaseInfo.diseaseName : "-"}</h5>
                                                </Col>
                                                <hr
                                                    className="ms-4 mt-3"
                                                    style={{ borderStyle: "dashed", borderWidth: "1px 0 0 0 ", width: "95%" }}
                                                />
                                            </Row>
                                        </Col>
                                        <Col sm={6}>
                                            <Row>
                                                <Col sm={3} className="text-muted">
                                                    <h5 className="text-body-quaternary mb-0">대상구분</h5>
                                                </Col>
                                                <Col sm={9}>
                                                    <h5 className="mb-0">
                                                        {OPTION_LIST.find((option) => option.no === diseaseInfo.targetCategoryNo)?.title}
                                                    </h5>
                                                </Col>
                                                <hr
                                                    className="ms-3 mt-3"
                                                    style={{ borderStyle: "dashed", borderWidth: "1px 0 0 0 ", width: "95%" }}
                                                />
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-md-start p-2">
                                        <Col sm={6}>
                                            <Row>
                                                <Col sm={3} className="text-muted">
                                                    <h5 className="text-body-quaternary mb-0">발병일(진단일)</h5>
                                                </Col>
                                                <Col sm={9}>
                                                    <h5 className="mb-0 ms-3">{diseaseInfo.dateOccur}</h5>
                                                </Col>
                                                <hr
                                                    className="ms-3 mt-3"
                                                    style={{ borderStyle: "dashed", borderWidth: "1px 0 0 0 ", width: "95%" }}
                                                />
                                            </Row>
                                        </Col>
                                        <Col sm={6}>
                                            <Row>
                                                <Col sm={3} className="text-muted">
                                                    <h5 className="text-body-quaternary mb-0">기준일(퇴원일)</h5>
                                                </Col>
                                                <Col sm={9}>
                                                    <h5 className="ms-4 mb-0">{diseaseInfo.dateBase}</h5>
                                                </Col>
                                                <hr
                                                    className="ms-4 mt-3"
                                                    style={{ borderStyle: "dashed", borderWidth: "1px 0 0 0 ", width: "95%" }}
                                                />
                                            </Row>
                                        </Col>
                                    </Row>

                                    <div className="mt-6 mb-3">
                                        <h4>검사정보</h4>
                                    </div>

                                    <Row className="justify-content-md-start p-2">
                                        <Col sm={4}>
                                            <Row>
                                                <Col sm={5} className="text-muted">
                                                    <h5 className="text-body-quaternary mb-0">1차 검사일</h5>
                                                </Col>
                                                <Col sm={7}>
                                                    <h5 className="mb-0">{inspectInfo.dateInspectFirst}</h5>
                                                </Col>
                                                <hr
                                                    className="ms-3 mt-3"
                                                    style={{ borderStyle: "dashed", borderWidth: "1px 0 0 0 ", width: "95%" }}
                                                />
                                            </Row>
                                        </Col>
                                        <Col sm={4}>
                                            <Row>
                                                <Col sm={5} className="text-muted">
                                                    <h5 className="text-body-quaternary mb-0">2차 검사일</h5>
                                                </Col>
                                                <Col sm={7}>
                                                    <h5 className="mb-0">{inspectInfo.dateInspectSecond}</h5>
                                                </Col>
                                                <hr
                                                    className="ms-3 mt-3"
                                                    style={{ borderStyle: "dashed", borderWidth: "1px 0 0 0 ", width: "95%" }}
                                                />
                                            </Row>
                                        </Col>
                                        <Col sm={4}>
                                            <Row>
                                                <Col sm={5} className="text-muted">
                                                    <h5 className="text-body-quaternary mb-0">3차 검사일</h5>
                                                </Col>
                                                <Col sm={7}>
                                                    <h5 className="mb-0">{inspectInfo.dateInspectThird}</h5>
                                                </Col>
                                                <hr
                                                    className="ms-3 mt-3"
                                                    style={{ borderStyle: "dashed", borderWidth: "1px 0 0 0 ", width: "92%" }}
                                                />
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className="justify-content-md-start p-2">
                                        <Col sm={6}>
                                            <Row>
                                                <Col sm={3} className="text-muted">
                                                    <h5 className="text-body-quaternary mb-0">안정 시 심박수</h5>
                                                </Col>
                                                <Col sm={9}>
                                                    <h5 className="ms-4 mb-0 ">{inspectInfo.restingHeartRate} Resting HR</h5>
                                                </Col>
                                                <hr
                                                    className="ms-3 mt-3"
                                                    style={{ borderStyle: "dashed", borderWidth: "1px 0 0 0 ", width: "95%" }}
                                                />
                                            </Row>
                                        </Col>
                                        <Col sm={6}>
                                            <Row>
                                                <Col sm={3} className="text-muted">
                                                    <h5 className="text-body-quaternary mb-0">최대 심박수</h5>
                                                </Col>
                                                <Col sm={9}>
                                                    <h5 className="mb-0">{inspectInfo.maxHeartRate} Maximal HR</h5>
                                                </Col>
                                                <hr
                                                    className="ms-3 mt-3"
                                                    style={{ borderStyle: "dashed", borderWidth: "1px 0 0 0 ", width: "95%" }}
                                                />
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className="justify-content-md-start p-2">
                                        <Col sm={6}>
                                            <Row>
                                                <Col sm={3} className="text-muted">
                                                    <h5 className="text-body-quaternary mb-0">위험군</h5>
                                                </Col>
                                                <Col sm={9}>
                                                    <h5 className="ms-4 mb-0">
                                                        {inspectInfo.riskGroup === 1
                                                            ? "저위험군"
                                                            : inspectInfo.riskGroup === 2
                                                            ? "중위험군"
                                                            : "고위험군"}
                                                    </h5>
                                                </Col>
                                                <hr
                                                    className="ms-4 mt-3"
                                                    style={{ borderStyle: "dashed", borderWidth: "1px 0 0 0 ", width: "95%" }}
                                                />
                                            </Row>
                                        </Col>
                                        <Col sm={6}>
                                            <Row>
                                                <Col sm={3} className="text-muted">
                                                    <h5 className="text-body-quaternary mb-0">METs</h5>
                                                </Col>
                                                <Col sm={9}>
                                                    <h5 className="mb-0">{inspectInfo.METs}</h5>
                                                </Col>
                                                <hr
                                                    className="ms-3 mt-3"
                                                    style={{ borderStyle: "dashed", borderWidth: "1px 0 0 0 ", width: "95%" }}
                                                />
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className="justify-content-md-start p-2">
                                        <Col sm={6}>
                                            <Row>
                                                <Col sm={3} className="text-muted">
                                                    <h5 className="text-body-quaternary mb-0">기타질병</h5>
                                                </Col>
                                                <Col sm={9}>
                                                    <h5 className="ms-4 mb-0">
                                                        {inspectInfo.etc !== "" && inspectInfo.etc !== null ? `있음 (${inspectInfo.etc})` : "없음"}
                                                    </h5>
                                                </Col>
                                                <hr
                                                    className="ms-3 mt-3"
                                                    style={{ borderStyle: "dashed", borderWidth: "1px 0 0 0 ", width: "95%" }}
                                                />
                                            </Row>
                                        </Col>
                                    </Row>

                                    <div className="mt-4 mb-3">
                                        <h4 className="float-start">상담내역</h4>
                                        <div className="mt-2 d-flex justify-content-end">
                                            <Button variant="primary" className="ms-2" onClick={handleOpenCounselingModal}>
                                                작성하기
                                            </Button>
                                        </div>
                                    </div>

                                    {isCounselingLoading ? (
                                        <div className="d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        </div>
                                    ) : counselingList?.data.counsel && counselingList?.data.counsel.length > 0 ? (
                                        <CounselingList data={counselingList?.data} type="patient" />
                                    ) : (
                                        <div className="order-1 order-xl-0 col-xl-12 col-12">
                                            <div className="mb-4 shadow-none border overflow-visible card">
                                                <div className="p-0 card-body">
                                                    <div className="p-15 text-center" style={{ height: "250px" }}>
                                                        <p className="mb-0 text-body-tertiary">
                                                            <b>상담내역이 없습니다.</b>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="mt-2 d-flex justify-content-end">
                                        <Button variant="secondary" className="ms-2" onClick={() => navigate("/ett/list")}>
                                            목록
                                        </Button>
                                        <Button
                                            variant="primary"
                                            className="ms-2"
                                            onClick={() => navigate(`/ett/modify?cycle=${cycle}&programId=${programId}`)}
                                        >
                                            수정하기
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CounselingModalFormIndex
                show={showCounselingModal}
                handleClose={handleCloseCounselingModal}
                targetId={patientUID}
                programId={programId}
                type={counselingType}
                etc={cycleId}
            />
        </>
    );
};

export default EttDetail;
