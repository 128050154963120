import ErrorBoundary from "components/ErrorBoundary";
import TrackPreviousPage from "hooks/TrackPreviousPage";
import { useState, useEffect, useCallback } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { RecoilRoot } from "recoil";
import QueryProvider from "providers/QueryProvider";
import logout from "utils/logout";
import { Toaster } from "sonner";
import useToggleStyle from "./hooks/useToggleStyle";
import { useAppContext } from "./providers/AppProvider";
import { useSettingsPanelContext } from "./providers/SettingsPanelProvider";
import { useUrlChangeEffect } from "./hooks/useUrlChangeEffect";
// import SettingsPanel from "./components/settings-panel/SettingsPanel";
// import SettingsToggle from "./components/settings-panel/SettingsToggle";
import SubdomainHandler from "./utils/SubdomainHandler";

const App = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [subdomainProcessed, setSubdomainProcessed] = useState(false);

    const { isStylesheetLoaded } = useToggleStyle();
    const { pathname } = useLocation();

    const {
        settingsPanelConfig: { showSettingPanelButton },
        setSettingsPanelConfig,
    } = useSettingsPanelContext();

    const {
        config: { theme, isRTL },
    } = useAppContext();

    const handleSubdomainProcessed = useCallback(() => {
        setSubdomainProcessed((prev) => !prev);
    }, []);

    // 경로 이름이 변경될 때마다 자동으로 상단으로 스크롤
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        setSettingsPanelConfig({
            openSettingPanel: false,
        });
    }, [isRTL]);

    //로그인 후 뒤로가기하면 로그라웃 처리
    useEffect(() => {
        const token = sessionStorage.getItem("token");
        if (location.pathname === "/" && token) {
            logout(navigate);
        }
    }, [location, navigate]);

    //url 변경시(IN/OUT) 이벤트 처리 관리하는곳(모듈별로 관리되게...)
    useUrlChangeEffect();

    return (
        <RecoilRoot>
            <QueryProvider>
                <ErrorBoundary>
                    <TrackPreviousPage />
                    <SubdomainHandler onSubdomainProcessed={handleSubdomainProcessed} />
                    {!isStylesheetLoaded ? (
                        <div
                            style={{
                                position: "fixed",
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                                backgroundColor: theme === "dark" ? "#000" : "#fff",
                            }}
                        />
                    ) : (
                        <>
                            <Outlet key={subdomainProcessed} />
                            <Toaster position="top-center" richColors closeButton theme={theme} />
                            {/* {showSettingPanelButton && (
                                <>
                                    <SettingsToggle />
                                    <SettingsPanel />
                                </>
                            )} */}
                        </>
                    )}
                </ErrorBoundary>
            </QueryProvider>
        </RecoilRoot>
    );
};

export default App;
