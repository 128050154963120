import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import MuiDatePicker from "components/base/MuiDatePicker";
import { TODAY } from "constants/monitoring";
import { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, ListGroup, Modal, Row, Spinner } from "react-bootstrap";

const PatientMngSerchModal = ({ isModalOpen, handleCloseModal, onPatientSelect }) => {
    const [searchResult, setSearchResult] = useState(null);

    const defaultSearchParams = {
        name: "",
        birth: TODAY,
        phoneUnit: {
            first: "010",
            middle: "",
            last: "",
        },
    };

    const [searchParams, setSearchParams] = useState(defaultSearchParams);

    useEffect(() => {
        if (!isModalOpen) {
            // 모달이 닫힐 때 상태 초기화
            setSearchResult(null);
            setSearchParams(defaultSearchParams);
        }
    }, [isModalOpen]);

    //환자 목록 검색
    const { mutate, isPending } = useMutation({
        mutationKey: ["patientList"],
        mutationFn: (requestData) => fetchData("POST", "/v1/management/ett/patient/search", requestData),
        onSuccess: ({ data }) => {
            setSearchResult(data?.list);
        },
        onError: (error) => {
            console.error("patientList 에러", error);
        },
    });

    //검색
    const handleSubmit = (e) => {
        e.preventDefault();
        const { name, birth, phoneUnit } = searchParams;
        const phone = phoneUnit.first + phoneUnit.middle + phoneUnit.last;
        mutate({ name, birth, phone });
    };

    //환자 선택
    const handelGetRowData = (patient) => {
        // 선택된 환자 정보를 부모 컴포넌트로 전달
        onPatientSelect({
            name: patient.name,
            birth: patient.birth,
            gender: patient.gender,
            phone: patient.phone,
            patientUID: patient.uid,
        });
    };

    return (
        <Modal show={isModalOpen} size="lg" onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>환자 검색</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Row className="p-2 mb-1">
                        <Col className="p-2 border d-flex align-items-center justify-content-center">
                            <p className="mb-0 text-body-tertiary fw-bold">환자명</p>
                        </Col>
                        <Col className="p-2 border d-flex align-items-center justify-content-center">
                            <Form.Group as={Col} controlId="formPatientName">
                                <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    maxLength={50}
                                    onChange={(e) => setSearchParams((prev) => ({ ...prev, name: e.target.value }))}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-2 border d-flex align-items-center justify-content-center">생년월일</Col>
                        <Col className="p-2 border d-flex align-items-center justify-content-center">
                            <MuiDatePicker
                                maxDate={TODAY}
                                onChange={(value) => {
                                    setSearchParams((prev) => ({ ...prev, birth: value }));
                                }}
                                value={TODAY}
                            />
                        </Col>
                    </Row>

                    <Row className="p-2 mb-1">
                        <Col xs={3} className="p-2 border d-flex align-items-center justify-content-center">
                            핸드폰번호
                        </Col>
                        <Col xs={9} className="p-2 border d-flex align-items-center justify-content-center">
                            <Form.Group as={Col} controlId="formPhoneNumber">
                                <InputGroup>
                                    <Form.Select
                                        className="w-10"
                                        onChange={(e) =>
                                            setSearchParams((prev) => ({ ...prev, phoneUnit: { ...prev.phoneUnit, first: e.target.value } }))
                                        }
                                    >
                                        <option>010</option>
                                        <option>011</option>
                                        <option>016</option>
                                        <option>017</option>
                                        <option>018</option>
                                        <option>019</option>
                                    </Form.Select>
                                    <Form.Control
                                        type="text"
                                        autoComplete="off"
                                        maxLength={4}
                                        onChange={(e) =>
                                            setSearchParams((prev) => ({ ...prev, phoneUnit: { ...prev.phoneUnit, middle: e.target.value } }))
                                        }
                                        required
                                    />
                                    <Form.Control
                                        type="text"
                                        autoComplete="off"
                                        maxLength={4}
                                        onChange={(e) =>
                                            setSearchParams((prev) => ({ ...prev, phoneUnit: { ...prev.phoneUnit, last: e.target.value } }))
                                        }
                                        required
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-end">
                        <Button variant="primary" className="w-25" type="submit">
                            검색
                        </Button>
                    </div>
                </Form>
                <div className="order-1 order-xl-0 col-xl-12 col-12 mt-3">
                    <div className="mb-4 shadow-none border overflow-visible card">
                        <div className="p-0 card-body">
                            {!searchResult || searchResult.length <= 0 ? (
                                <>
                                    {isPending ? (
                                        <div className="d-flex justify-content-center align-items-center">
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        </div>
                                    ) : (
                                        <div className="d-flex align-items-center justify-content-center" style={{ height: "250px" }}>
                                            <p className="text-body-tertiary">
                                                <b>조회된 검색결과가 없습니다.</b>
                                            </p>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <ListGroup variant="flush" className="my-3 overflow-y-auto ps-3" style={{ height: "300px" }}>
                                    <ListGroup.Item>
                                        <Row className="p-2 mb-2 align-middle">
                                            {searchResult.map((item, index) => (
                                                <Col
                                                    className="p-2 border d-flex align-items-center justify-content-center cursor-pointer"
                                                    sm={6}
                                                    style={{ height: "60px" }}
                                                    key={index}
                                                    onClick={() => handelGetRowData(item)}
                                                >
                                                    {item.name}{" "}
                                                    <span className="ms-5">
                                                        {item.birth} ({item.gender === "male" ? "남" : "여"})
                                                    </span>
                                                </Col>
                                            ))}
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            )}
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default PatientMngSerchModal;
