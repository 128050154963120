//메뉴별 권한 레벨 체크 추가
export const routes = [
    {
        label: "ett",
        active: true,
        labelDisabled: true,
        hasNew: false,
        pages: [
            {
                name: "운동부하검사 등록",
                icon: "users",
                path: "/ett/patients",
                pathPattern: "^/ett(/.*)?$", // 사용자 url 접근권한 경로
                pathName: "ett",
                active: true,
                flat: true,
                viewYn: true, // 메뉴 숨김처리
                roleLvl: [1, 2],
            },
        ],
    },
    {
        label: "monitoring",
        active: true,
        labelDisabled: true,
        hasNew: false,
        pages: [
            {
                name: "환자관리",
                icon: "users",
                path: "/monitoring/patients",
                pathPattern: "^/monitoring(/.*)?$", // 사용자 url 접근권한 경로
                pathName: "monitoring",
                active: true,
                flat: true,
                viewYn: true, // 메뉴 숨김처리
                roleLvl: [1, 2],
            },
        ],
    },
    {
        label: "settings",
        active: true,
        labelDisabled: true,
        hasNew: false,
        pages: [
            {
                name: "설정 관리",
                icon: "settings",
                pathName: "settings",
                active: true,
                flat: true,
                dropdownInside: true,
                viewYn: true, // 메뉴 숨김처리
                pages: [
                    {
                        name: "운영자 관리",
                        path: "/operator-setting",
                        pathPattern: "^/operator-setting(/.*)?$", // 사용자 url 접근권한 경로
                        pathName: "settings-operator-setting",
                        roleLvl: [0],
                        topNavIcon: "user",
                        active: true,
                        flat: true,
                        viewYn: true, // 메뉴 숨김처리
                    },
                    {
                        name: "실무자 관리",
                        path: "/staff-setting",
                        pathPattern: "^/staff-setting(/.*)?$", // 사용자 url 접근권한 경로
                        pathName: "settings-staff-setting",
                        roleLvl: [0],
                        topNavIcon: "users",
                        active: true,
                        flat: true,
                        viewYn: true, // 메뉴 숨김처리
                    },
                    {
                        name: "내정보 관리", // 운영자 메뉴
                        path: "/operator-my-info",
                        pathPattern: "^/operator-my-info(/.*)?$", // 사용자 url 접근권한 경로
                        pathName: "operator-my-info",
                        roleLvl: [1],
                        topNavIcon: "user",
                        active: true,
                        flat: true,
                        viewYn: true, // 메뉴 숨김처리
                    },
                    {
                        name: "실무자 관리",
                        path: "/staff-lower-setting",
                        pathPattern: "^/staff-lower-setting(/.*)?$", // 사용자 url 접근권한 경로
                        pathName: "staff-lower-setting",
                        roleLvl: [1],
                        topNavIcon: "users",
                        active: true,
                        flat: true,
                        viewYn: true, // 메뉴 숨김처리
                    },
                    {
                        name: "내정보 관리", //실무자 메뉴
                        path: "/staff-my-info",
                        pathPattern: "^/staff-my-info(/.*)?$", // 사용자 url 접근권한 경로
                        pathName: "staff-my-info",
                        roleLvl: [2],
                        topNavIcon: "users",
                        active: true,
                        flat: true,
                        viewYn: true, // 메뉴 숨김처리
                    },
                    {
                        name: "환자 관리(임시)",
                        path: "/user-setting",
                        pathName: "settings-user-settingg",
                        topNavIcon: "users",
                        active: true,
                        flat: true,
                        viewYn: false, // 메뉴 숨김처리
                        roleLvl: [99],
                    },
                ],
            },
        ],
    },
];
