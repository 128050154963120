import { useState } from "react";
import { Modal, Button, Form, ListGroup, Row, Col } from "react-bootstrap";
import { OPTION_LIST } from "constants/monitoringReason";

const MonitoringVerificationModal = ({ isModalOpen, handleCloseModal, handleLoadMonitoringData, selectPtInfo }) => {
    const [reasonNo, setReasonNo] = useState(1);

    return (
        <Modal show={isModalOpen} size="lg" onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>환자 선택 사유 입력</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul>
                    <li>이 환자 정보에 접근하시면 이력이 저장, 보관되어 실시간 모니터링 되고 있습니다.</li>
                    <li>
                        <span>업무와 관련 없는 무단열람은 형사 처벌 대상</span>이 될 수 있으며, 그래도 선택한 환자의 정보를 열람하시고자 하면 "예"를
                        아니면 "아니오"를 클릭하십시오.
                    </li>
                </ul>
                <ListGroup variant="flush" className="my-3 overflow-y-auto ps-3" style={{ height: "350px" }}>
                    {OPTION_LIST.map((option) => (
                        <ListGroup.Item key={option.no}>
                            <Row className="p-2 mb-2 align-middle" style={{ height: "45px" }}>
                                <Col sm={2}></Col>
                                <Col sm={5} className="p-2">
                                    <Form.Check
                                        type="radio"
                                        id={`radio-${option.no}`}
                                        label={option.title}
                                        checked={reasonNo === option.no}
                                        onChange={() => setReasonNo(option.no)}
                                    />
                                </Col>
                                <Col sm={5} className="p-2">
                                    <small className="text-muted">{option.description}</small>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    아니오
                </Button>
                <Button variant="primary" onClick={() => handleLoadMonitoringData(reasonNo, selectPtInfo)}>
                    예
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MonitoringVerificationModal;
