import { useQuery } from "@tanstack/react-query";
import fetchData from "apis/fetchData";

export const useEttData = (initialCycle, programId) => {
    const fetchEttData = async (cycle, isRecursive = false) => {
        try {
            const response = await fetchData("POST", `/v1/management/ett/load/cycle`, { cycle, programId });

            if (response.data && Object.keys(response.data).length > 0) {
                return {
                    ...response,
                    actualCycle: cycle.toString(),
                    isRecursive,
                };
            }

            // 데이터가 없으면 cycle을 감소시키고 재귀적으로 호출
            if (cycle > 1) {
                return fetchEttData(cycle - 1, true);
            }

            throw new Error("No data found for any cycle");
        } catch (error) {
            console.error(`Error fetching data for cycle ${cycle}:`, error);
            if (cycle > 1) {
                return fetchEttData(cycle - 1, true);
            }
            throw error;
        }
    };

    return useQuery({
        queryKey: ["ettData", initialCycle + "_etd" + programId],
        queryFn: () => fetchEttData(initialCycle),
        enabled: !!initialCycle && !!programId,
    });
};
