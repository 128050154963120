import React from "react";
import PatientListMonitoring from "components/patients/monitoring/PatientListPage";
import PatientListEtt from "components/patients/ett/PatientListPage";

export const IdxPatientListMonitoring = () => {
    return <PatientListMonitoring />;
};

export const IdxPatientListEtt = () => {
    return <PatientListEtt />;
};
