import React, { useState, useEffect, forwardRef } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAppContext } from "providers/AppProvider";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/ko";

dayjs.locale("ko");
dayjs.extend(utc);
dayjs.extend(timezone);

const MuiDatePicker = forwardRef(
    ({ minDate, maxDate, onChange, value, labelProp, disablProp = false, readOnlyProp = false, isInvalid = false }, ref) => {
        // 테마 정보를 Context로부터 직접 받음
        const {
            config: { theme },
        } = useAppContext();

        //다크 테마 스타일
        const textFieldDarkStyles =
            theme === "dark"
                ? {
                      border: "solid #373e53",
                      color: "#9fa6bc",
                      backgroundColor: "#141824",
                  }
                : "";
        const deactivTextFieldDarkStyles =
            theme === "dark"
                ? {
                      border: "solid #373e53",
                      color: "#9fa6bc",
                      backgroundColor: "#464e69",
                  }
                : {
                      color: "#9fa6bc",
                  };

        let textFieldStyle = {
            ...(isInvalid ? { border: "1px solid red" } : {}),
            ...textFieldDarkStyles,
        };

        if (disablProp || readOnlyProp) {
            textFieldStyle = {
                ...deactivTextFieldDarkStyles,
                ...(isInvalid ? { border: "1px solid red" } : {}),
            };
        }

        return (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
                <DatePicker
                    label={labelProp}
                    {...(value && { value: dayjs(value) })}
                    format="YYYY-MM-DD"
                    readOnly={readOnlyProp}
                    disabled={disablProp}
                    onChange={(newValue) => {
                        if (newValue && newValue.isValid()) {
                            const koreaFormattedDate = newValue.tz("Asia/Seoul").format("YYYY-MM-DD");
                            onChange(koreaFormattedDate);
                        } else {
                            onChange(""); // 유효하지 않은 경우 적절한 기본값 설정
                        }
                    }}
                    {...(maxDate && { maxDate: dayjs(maxDate) })}
                    {...(minDate && { minDate: dayjs(minDate) })}
                    slotProps={{
                        textField: {
                            variant: "outlined",
                            error: isInvalid,
                            inputRef: ref,
                        },
                    }}
                    sx={{
                        width: "100%",
                        "& .MuiInputLabel-root.Mui-focused": { color: "#979797" },
                        "& .MuiOutlinedInput-root": {
                            "&:hover > fieldset": { borderColor: "#C7C8CD" },
                            height: "37px",
                            borderRadius: "6px",
                            background: "white",
                        },
                        "& .MuiInputBase-root": textFieldStyle,
                        "& .MuiFormLabel-root": {
                            color: "#bdbdbd",
                        },
                    }}
                />
            </LocalizationProvider>
        );
    },
);

export default MuiDatePicker;
