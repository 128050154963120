import MonitoringDetailContents from "components/MonitoringDetail/MonitoringDetailContents";
import { useEffect } from "react";
import { Row, Button } from "react-bootstrap";
import { useNavigate, useNavigationType, useLocation } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { currentDate } from "store/atoms";

const MonitoringDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // const searchDate = useRecoilValue(currentDate);
    const { currentPage = 1, date } = location.state || {};

    const handleGoBack = () => {
        navigate(`/monitoring?page=${currentPage}`);
    };

    return (
        <>
            <div className="pb-5">
                <Row className="g-2">
                    <div className="mb-2">
                        <h2>환자 모니터링</h2>
                    </div>
                    <hr className="bg-body-secondary mb-3" />
                </Row>
                <div className="order-1 order-xl-0 col-xl-12 col-12">
                    <div className="mb-4 shadow-none border overflow-visible card">
                        <div className="p-0 card-body">
                            <div className="p-4">
                                {/* {searchDate && <MonitoringDetailContents searchDate={searchDate} handleGoBack={handleGoBack} />} */}
                                <MonitoringDetailContents searchDate={date} handleGoBack={handleGoBack} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MonitoringDetail;
