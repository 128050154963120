import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import MuiDatePicker from "components/base/MuiDatePicker";
import { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import WeeklySquat from "./WeeklySquat";
import WeeklyStretching from "./WeeklyStretching";
import WeeklyWalking from "./WeeklyWalking";
import calculateEndDate from "../../utils/calculateEndDate";

const WeeklyWorkoutResult = ({ searchDate: initialSearchDate, weeks }) => {
    const [searchDate, setSearchDate] = useState(initialSearchDate);
    const navigate = useNavigate();
    const [maxDate, setMaxDate] = useState("");
    const patientUID = sessionStorage.getItem("patientUID");

    const { mutate: fetchStretching, data: stretchingData } = useMutation({
        mutationFn: () => fetchData("POST", `/v1/monitoring/workout/weeks/stretching`, { patientUID, searchDate, weeks }),
    });

    const { mutate: fetchWalking, data: walkingData } = useMutation({
        mutationFn: () => fetchData("POST", `/v1/monitoring/workout/weeks/walking`, { patientUID, searchDate, weeks }),
    });

    const { mutate: fetchSquat, data: squatData } = useMutation({
        mutationFn: () => fetchData("POST", `/v1/monitoring/workout/weeks/squat`, { patientUID, searchDate, weeks }),
    });

    useEffect(() => {
        // Calculate and set the end date
        setSearchDate(initialSearchDate);
        const calculatedEndDate = calculateEndDate(initialSearchDate, weeks);
        setMaxDate(calculatedEndDate);

        fetchStretching();
        fetchWalking();
        fetchSquat();
    }, [initialSearchDate, weeks]);

    const handleClickDate = (value) => {
        setSearchDate(value);
    };

    return (
        <>
            <Row className="mb-4">
                <Col md={4}>
                    <MuiDatePicker onChange={handleClickDate} labelProp="시작일" value={searchDate} readOnlyProp="true" />
                </Col>
                <Col md="auto" className="d-flex align-items-center">
                    ~
                </Col>
                <Col md={4}>
                    {/* <Form.Control type="date" defaultValue={searchDateEnd} disabled /> */}
                    <MuiDatePicker value={maxDate} labelProp="종료일" readOnlyProp="true" />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <WeeklyStretching duration={weeks} stretchingResult={stretchingData?.data.stretching} />
                </Col>
                <Col md={12}>
                    <WeeklyWalking duration={weeks} walkingResult={walkingData?.data.walking} />
                </Col>
                <Col md={12}>
                    <WeeklySquat duration={weeks} squatResult={squatData?.data.squat} />
                </Col>
            </Row>
        </>
    );
};

export default WeeklyWorkoutResult;
