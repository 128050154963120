import { instance } from "apis";

/**
 * 주어진 API 엔드포인트로 HTTP 요청을 보내 데이터를 가져옵니다.
 *@example GET - fetchData('GET', '/users', null, params);
 *@example POST - fetchData('POST', '/users', requestData);
 *@example PUT - fetchData('PUT', '/users/1', requestData);
 *@example DELETE - fetchData('DELETE', '/users/1');
 
 * @function fetchData
 * @param {string} method - HTTP 메서드 (GET, POST, PUT, DELETE 등).
 * @param {string} endpoint - 요청을 보낼 API 엔드포인트.
 * @param {Object} [requestData] - 요청의 바디에 포함될 데이터 (선택적).
 * @param {Object} [params] - URL 쿼리 파라미터 (선택적, GET 요청에 주로 사용).
 * @returns {Promise<Object>} - API로부터의 응답을 반환합니다.
 */
const fetchData = async (method, endpoint, requestData = null, params = null) => {
    const config = {
        method: method.toUpperCase(),
        url: endpoint,
    };

    if (requestData) {
        config.data = requestData;
    }

    if (params) {
        config.params = params;
    }

    try {
        const response = await instance(config);
        return response;
    } catch (error) {
        console.error("Error in fetchData:", error);
        throw error;
    }
};

export default fetchData;
