import { Table } from "react-bootstrap";
import WorkoutSummary from "components/WorkoutResult/WorkoutSummary";
import SummaryTextbox from "components/MonitoringDetail/SummaryTextbox";
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import CustomTooltip from "components/MonitoringDetail/CustomTooltip";
import FeatherIcon from "feather-icons-react";

const DailyStretching = ({ stretchingData }) => {
    const { summary = {}, graph = [], list = [] } = stretchingData || {};
    const { cycle = 0, runningTime = "-", heartRate = "-" } = summary;

    const CustomAreaTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const { max, min } = payload[0].payload;
            return (
                <CustomTooltip
                    label={label}
                    items={[
                        { color: "#BE1D95", text: `최대 심박수 : ${max}` },
                        { color: "#353963", text: `최소 심박수 : ${min}` },
                    ]}
                />
            );
        }
        return null;
    };

    return (
        <div className="mt-5">
            <h1 className="mb-2">스트레칭</h1>
            <WorkoutSummary dataType="일일 운동 통계">
                <div className="px-3 mb-5">
                    <div className="justify-content-between row">
                        <SummaryTextbox value={`${cycle} 회`} description="운동횟수" col={4} />
                        <SummaryTextbox value={runningTime} description="운동시간(분:초)" startBorder={true} col={4} />
                        <SummaryTextbox value={heartRate} description="최소/대 심박수(bpm)" startBorder={true} col={4} />
                    </div>
                </div>

                <ResponsiveContainer width="100%" height={300}>
                    <AreaChart data={graph} margin={{ top: 20, right: 30, left: 20, bottom: 0 }}>
                        <defs>
                            <linearGradient id="gradientMax" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor="rgba(190,29,149,1)" stopOpacity={1} />
                                <stop offset="100%" stopColor="rgba(190,29,149,0)" stopOpacity={0} />
                            </linearGradient>
                            <linearGradient id="gradientMin" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor="rgba(53,57,99,1)" stopOpacity={1} />
                                <stop offset="100%" stopColor="rgba(53,57,99,0)" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis dataKey="index" stroke="dotted" />
                        <YAxis stroke="none" />
                        <Tooltip content={<CustomAreaTooltip />} />
                        <Legend verticalAlign="top" align="right" wrapperStyle={{ paddingBottom: "10px", width: "100%", fontSize: "15px" }} />
                        <Area type="monotone" dataKey="max" stroke="#BE1D95" strokeWidth={2} fill="url(#gradientMax)" name="최대심박수" />
                        <Area type="monotone" dataKey="min" stroke="#353963" strokeWidth={2} fill="url(#gradientMin)" name="최소심박수" />
                    </AreaChart>
                </ResponsiveContainer>

                <div>
                    <h4 className="mt-4 mb-3">일일 기록</h4>
                    <div className="scrollbar ms-n1 ps-1">
                        <Table bordered hover size="sm" className="phoenix-table fs-9 mb-0 border-top">
                            <thead className="bg-body-highlight">
                                <tr>
                                    <th className="text-end text-body-tertiary pe-2" style={{ width: "6%", minWidth: "30px" }}>
                                        회차
                                    </th>
                                    <th className="text-end px-3 text-body-tertiary" style={{ width: "20%", minWidth: "100px" }}>
                                        <div className="d-inline-flex flex-center gap-2">
                                            <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                className="svg-inline--fa fa-square fs-11 text-primary"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                                style={{ width: "10px" }}
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"
                                                />
                                            </svg>
                                            운동시간(분:초)
                                        </div>
                                    </th>
                                    <th className="text-end px-3 text-body-tertiary" style={{ width: "24%", minWidth: "125px" }}>
                                        <div className="d-inline-flex flex-center gap-2">
                                            <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                className="svg-inline--fa fa-square fs-11 text-primary-light"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                                style={{ width: "10px" }}
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"
                                                />
                                            </svg>
                                            최소/대 심박수(bpm)
                                        </div>
                                    </th>
                                    <th className="text-end px-3 text-body-tertiary" style={{ width: "26%", minWidth: "160px" }}>
                                        <div className="d-inline-flex flex-center gap-2">
                                            <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                className="svg-inline--fa fa-square fs-11 text-success"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                                style={{ width: "10px" }}
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"
                                                />
                                            </svg>
                                            휴식시간(시간/횟수)
                                        </div>
                                    </th>
                                    <th className="text-end text-body-tertiary  pe-3" style={{ width: "26%", minWidth: "150px" }}>
                                        <div className="d-inline-flex flex-center gap-2">
                                            <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                className="svg-inline--fa fa-square fs-11 text-danger"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                                style={{ width: "10px" }}
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"
                                                />
                                            </svg>
                                            안정시간(시간/횟수)
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {list && list.length > 0 ? (
                                    list.map(({ index, runningTime, heartRate, restingSelf, restingDanger }) => (
                                        <tr key={index}>
                                            <td className="text-end pe-3 white-space-nowrap ps-2">{index}</td>
                                            <td className="text-end fw-semibold text-body py-2 px-3">{runningTime}</td>
                                            <td className="text-end fw-semibold text-body py-2 px-3">{heartRate}</td>
                                            <td className="text-end fw-semibold text-body py-2 px-3">{restingSelf}</td>
                                            <td className="text-end fw-semibold text-body py-2 pe-3">{restingDanger}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td className="text-center fw-semibold text-body py-2 px-3" colSpan={5}>
                                            <h4>데이터가 없습니다.</h4>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </WorkoutSummary>
        </div>
    );
};

export default DailyStretching;
