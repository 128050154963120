import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkDomainValidity } from "../service/subdomainService";

const SubdomainHandler = ({ onSubdomainProcessed }) => {
    const navigate = useNavigate();
    const [isProcessed, setIsProcessed] = useState(false);

    useEffect(() => {
        const handleSubdomain = async () => {
            const hostname = window.location.hostname;
            const parts = hostname.split(".");
            let domain = "";

            if (hostname.includes("localhost")) {
                const localParts = hostname.split(".localhost");
                if (localParts.length > 1 && localParts[0]) {
                    domain = localParts[0];
                }
            } else if (parts.length > 2) {
                domain = parts[0];
            }

            if (domain) {
                try {
                    const { mode, name, msg } = await checkDomainValidity(domain);
                    if (mode) {
                        const infHsp = {
                            domain: domain,
                            name: name,
                        };
                        sessionStorage.setItem("infHsp", JSON.stringify(infHsp));
                        setIsProcessed(true);
                        onSubdomainProcessed();
                        navigate("/");
                    } else {
                        navigate("/not-found");
                    }
                } catch (error) {
                    console.error("Error checking domain validity:", error);
                    navigate("/not-found");
                }
            }
        };

        handleSubdomain();
    }, [navigate, onSubdomainProcessed]);

    return null;
};

export default SubdomainHandler;
