import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import { Modal } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import formatTime from "utils/formatTime";
import { Form, Button } from "react-bootstrap";
import { toast } from "sonner";

const totalInputLength = 6;

const UserCertificationModal = ({ isModalOpen, handleCloseModal, handleSendLoginRequest, uuid, role }) => {
    const navigate = useNavigate();
    const INITIAL_TIME = 180;
    const timerRef = useRef(null);

    const [remainingTime, setRemainingTime] = useState(INITIAL_TIME);
    const [isResend, setIsResend] = useState(false);
    const [smsCertiNumber, setSmsCertiNumber] = useState("");

    const inputRefs = useRef([]);

    const handleChange = (event, index) => {
        const { value } = event.target;

        inputRefs.current[index].value = value.slice(0, 1);

        if (value && index < totalInputLength - 1) {
            inputRefs.current[index + 1].focus();
        }

        if (event.keyCode === 8 && !value && index > 0) {
            inputRefs.current[index - 1].focus();
        }

        const updatedOtp = inputRefs.current.map((input) => input.value || "").join("");
        setSmsCertiNumber(updatedOtp);
    };

    const startTimer = () => {
        if (timerRef.current) clearInterval(timerRef.current);
        timerRef.current = setInterval(() => {
            setRemainingTime((prevTime) => {
                if (prevTime > 0) {
                    return prevTime - 1;
                } else {
                    setIsResend(true);
                    clearInterval(timerRef.current);
                    return 0;
                }
            });
        }, 1000);
    };

    useEffect(() => {
        if (isModalOpen) {
            setRemainingTime(INITIAL_TIME);
            setIsResend(false);
            startTimer();
        }

        return () => clearInterval(timerRef.current);
    }, [isModalOpen]);

    //모달창 열려있을때 esc키 입력시 모달창 닫히게 처리
    useEffect(() => {
        const handleEscKey = (event) => {
            if (event.key === "Escape") {
                handleCloseModal();
            }
        };

        if (isModalOpen) {
            window.addEventListener("keydown", handleEscKey);
        }

        return () => {
            window.removeEventListener("keydown", handleEscKey);
        };
    }, [isModalOpen, handleCloseModal]);

    const { mutate: smsCertificateMutate } = useMutation({
        mutationFn: (requestData) => fetchData("POST", "/v1/auth/logIn/smsCerti", requestData),
        onSuccess: (response) => {
            if (response.result === "pass") {
                const token = response.data.token;
                sessionStorage.setItem("token", token);
                navigate("/ett/patients");
            }
        },
        onError: ({ response }) => {
            if (response.status === 401) {
                toast.error("인증번호가 맞지 않습니다.");
                setSmsCertiNumber("");
            }
        },
    });

    const handleSubmitCertiNumber = (e) => {
        e.preventDefault();
        if (remainingTime > 0) {
            // if (smsCertiNumber === "111111") smsCertificateMutate({ uuid, smsCertiNumber, role });
            // else smsCertificateMutate({ uuid, smsCertiNumber });
            smsCertificateMutate({ uuid, smsCertiNumber });
        } else {
            setIsResend(true);
        }
    };

    const handleClickResend = (e) => {
        e.preventDefault();
        handleSendLoginRequest();
        setIsResend(false);
        setRemainingTime(INITIAL_TIME);
        startTimer();
    };

    return (
        <>
            <Modal show={isModalOpen} onHide={handleCloseModal} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
                <Form onSubmit={handleSubmitCertiNumber}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">인증번호 입력</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <h4>Centered Modal</h4> */}
                        <p style={{ padding: "10px" }}>사용자 인증을 위해 등록하신 핸드폰 번호로 발송한 6자리 번호를 입력해 주세요.</p>
                        <Form.Group controlId="smsCertiNumber">
                            <div className="verification-form">
                                <div className="d-flex align-items-center gap-2 mb-3">
                                    {Array(totalInputLength)
                                        .fill("")
                                        .map((_, index) => (
                                            <React.Fragment key={index}>
                                                <Form.Control
                                                    ref={(el) => {
                                                        inputRefs.current[index] = el;
                                                    }}
                                                    maxLength="1"
                                                    className="px-2 text-center"
                                                    type="number"
                                                    autoComplete="off"
                                                    onKeyDown={(e) => handleChange(e, index)}
                                                    onInput={(e) => handleChange(e, index)}
                                                />
                                            </React.Fragment>
                                        ))}
                                </div>
                            </div>
                        </Form.Group>

                        <div className="mx-auto pd-10" style={{ width: "120px" }}>
                            유효시간 {formatTime(remainingTime)}
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        {!isResend && (
                            <Button type="submit" className="w-100">
                                인증하기
                            </Button>
                        )}
                        {isResend && (
                            <Button onClick={handleClickResend} className="w-100 bg-warning">
                                재전송
                            </Button>
                        )}
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export default UserCertificationModal;
