import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import { useEffect } from "react";
import { Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import StaffInfo from "./StaffInfo";

const StaffDetail = () => {
    const [searchParams] = useSearchParams();
    const staffUUID = searchParams.get("id");

    const { data: staffDetail, mutate: staffDetailMutate } = useMutation({
        mutationFn: (requestData) => fetchData("POST", "/v1/setting/staff/detail", requestData),
    });

    useEffect(() => {
        staffDetailMutate({ staffUUID });
    }, [staffDetailMutate, staffUUID]);

    return (
        <div className="pb-5">
            <Row className="g-2">
                <div className="mb-2">
                    <h2>실무자 정보</h2>
                </div>
                <hr className="bg-body-secondary mb-3" />
            </Row>
            <div className="order-1 order-xl-0 col-xl-12 col-12">
                <div className="mb-4 shadow-none border overflow-visible card">
                    <div className="p-0 card-body">
                        <div className="p-4">
                            <Row className="g-2">
                                <div className="mb-2">
                                    <h3>기본 정보</h3>
                                </div>
                                <hr className="bg-body-secondary mb-3" />
                            </Row>
                            <StaffInfo staffDetail={staffDetail?.data.staff} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StaffDetail;
