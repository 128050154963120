import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import HospitalInfoTable from "../HospitalInfoTable";
import StaffListTable from "./StaffListTable";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row } from "react-bootstrap";

const StaffSetting = () => {
    const { data: staffInfo, mutate: staffInfoMutate } = useMutation({
        mutationFn: () => fetchData("GET", "/v1/setting/staff/list"),
    });

    useEffect(() => {
        staffInfoMutate();
    }, [staffInfoMutate]);

    return (
        <div className="pb-5">
            <Row className="g-2">
                <div className="mb-2">
                    <h2>실무자 관리</h2>
                </div>
                <hr className="bg-body-secondary mb-3" />
            </Row>

            <div className="order-1 order-xl-0 col-xl-12 col-12">
                <div className="mb-4 shadow-none border overflow-visible card">
                    <div className="p-0 card-body">
                        <div className="p-4">
                            {staffInfo && (
                                <>
                                    <div className="mb-8">
                                        <HospitalInfoTable hospitalInfo={staffInfo.data.hospitalInfo} />
                                    </div>
                                    <StaffListTable staffList={staffInfo.data.staff} />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StaffSetting;
