import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    ALLOWED_IMAGE_FORMATS_MESSAGE,
    CONFIRM_ID,
    DULICATED_ID,
    EMPTY_INPUT,
    ERROR_COPORATION_NUMBER,
    ERROR_ID_CHECK,
    ERROR_ID_EMPTY,
    ERROR_PASSWORD_MATCH,
    REGIST_CHECK,
    REGIST_FAIL,
} from "constants/errorMessage";
import {
    ALPHA_NUMBER_PLACEHOLER,
    COMMENTS_PLACEHOLDER,
    HOSPITAL_NAME_PLACEHOLDER,
    LOGO_PLACEHOLDER,
    NUMBER_ONLY_PLACEHOLDER,
} from "constants/placeholder";
import { ID_REGEX, NUMBER_ONLY_REGEX } from "constants/regex";
import checkIsEmpty from "utils/checkIsEmpty";
import { toast } from "sonner";

const HospitalRegisterForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        hospitalName: "",
        coporationNumber: "",
        postNumber: "",
        addressMajor: "",
        addressMinor: "",
        id: "",
        password: "",
        passwordCheck: "",
        logo: "",
        comments: "",
    });
    const [isValidId, setIsValidId] = useState(false);
    const idRef = useRef(null);
    const passwordRef = useRef(null);

    const { mutate: checkDuplicateIdMutate } = useMutation({
        mutationFn: () => fetchData("POST", "/admin/v1/auth/check/logInId", { checkId: formData.id }),
        onSuccess: (data) => {
            if (data.result === "pass") {
                const result = window.confirm(`${formData.id} ${CONFIRM_ID}`);
                if (result) {
                    setIsValidId(true);
                    passwordRef.current.focus();
                } else {
                    idRef.current.focus();
                }
            }
        },
        onError: ({ response }) => {
            if (response.status === 409) {
                toast.error(`${formData.userId} ${DULICATED_ID}`);
                return;
            }
        },
    });

    const { mutate: registMutate } = useMutation({
        mutationFn: (requestData) => fetchData("POST", "/admin/v1/auth/regist", requestData),
        onSuccess: () => {
            navigate("/hospital/list");
        },
        onError: () => toast.error(REGIST_FAIL),
    });

    const handleIdChange = (e) => {
        setIsValidId(false);
        setFormData((prev) => {
            return { ...prev, id: e.target.value };
        });
    };

    const handleChangeInput = (e, label) =>
        setFormData((prev) => {
            return { ...prev, [label]: e.target.value };
        });

    const handleCheckDuplicateId = () => {
        setIsValidId(false);

        if (!formData.id) {
            toast.error(ERROR_ID_EMPTY);
            return;
        }

        if (!ID_REGEX.test(formData.id)) {
            toast.error(ERROR_ID_CHECK);
            return;
        }

        checkDuplicateIdMutate();
    };

    const handleRegist = async (e) => {
        e.preventDefault();
        const { hospitalName, coporationNumber, postNumber, addressMajor, addressMinor, id, password, passwordCheck, logo, comments } = formData;

        if (!isValidId) {
            toast.error(DULICATED_ID);
            return;
        }

        if (!checkIsEmpty(hospitalName, coporationNumber, postNumber, addressMajor, addressMinor, id, password, passwordCheck)) {
            toast.error(EMPTY_INPUT);
            return;
        }

        if (password !== passwordCheck) {
            toast.error(ERROR_PASSWORD_MATCH);
            return;
        }

        if (!NUMBER_ONLY_REGEX.test(coporationNumber)) {
            toast.error(ERROR_COPORATION_NUMBER);
            return;
        }

        if (window.confirm(REGIST_CHECK)) {
            registMutate({
                hospitalName,
                coporationNumber,
                postNumber,
                addressMajor,
                addressMinor,
                userI: id,
                userPassword: password,
                logo,
                comments,
            });
        }
    };

    return (
        <Container>
            <Form onSubmit={handleRegist}>
                <Row>
                    <Col>
                        <Form.Group controlId="hospitalName">
                            <Form.Label>병원명</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={HOSPITAL_NAME_PLACEHOLDER}
                                value={formData.hospitalName}
                                onChange={(e) => handleChangeInput(e, "hospitalName")}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="coporationNumber">
                            <Form.Label>사업자등록번호</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={NUMBER_ONLY_PLACEHOLDER}
                                value={formData.coporationNumber}
                                onChange={(e) => handleChangeInput(e, "coporationNumber")}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="postNumber">
                            <Form.Label>우편번호</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="우편번호"
                                value={formData.postNumber}
                                onChange={(e) => handleChangeInput(e, "postNumber")}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Button disabled>우편번호 검색</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="addressMajor">
                            <Form.Control
                                type="text"
                                placeholder="기본 주소를 입력해주세요"
                                value={formData.addressMajor}
                                onChange={(e) => handleChangeInput(e, "addressMajor")}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="addressMinor">
                            <Form.Control
                                type="text"
                                placeholder="상세 주소를 입력해주세요"
                                value={formData.addressMinor}
                                onChange={(e) => handleChangeInput(e, "addressMinor")}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="adminId">
                            <Form.Label>관리자 아이디</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={ALPHA_NUMBER_PLACEHOLER}
                                value={formData.id}
                                onChange={handleIdChange}
                                ref={idRef}
                                required
                            />
                            <Form.Text>아이디는 "admin_"으로 시작합니다.</Form.Text>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Button onClick={handleCheckDuplicateId}>아이디 중복확인</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="password">
                            <Form.Label>비밀번호</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder={ALPHA_NUMBER_PLACEHOLER}
                                value={formData.password}
                                onChange={(e) => handleChangeInput(e, "password")}
                                ref={passwordRef}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="passwordCheck">
                            <Form.Label>비밀번호 확인</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder={ALPHA_NUMBER_PLACEHOLER}
                                value={formData.passwordCheck}
                                onChange={(e) => handleChangeInput(e, "passwordCheck")}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="logo">
                            <Form.Label>로고 이미지</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={LOGO_PLACEHOLDER}
                                value={formData.logo}
                                onChange={(e) => handleChangeInput(e, "logo")}
                            />
                            <Form.Text>{ALLOWED_IMAGE_FORMATS_MESSAGE}</Form.Text>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Button>이미지 등록</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="comments">
                            <Form.Label>비고</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder={COMMENTS_PLACEHOLDER}
                                value={formData.comments}
                                onChange={(e) => handleChangeInput(e, "comments")}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button variant="secondary">취소</Button>
                        <Button type="submit">등록하기</Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
};

export default HospitalRegisterForm;
