import React, { useState, useEffect } from "react";
import { Form, Button, InputGroup, Row, Col } from "react-bootstrap";
import MuiDatePicker from "components/base/MuiDatePicker";
import { TODAY } from "constants/monitoring";

const SearchBar = ({ onSearch, onReset, initialSearchData }) => {
    const [searchName, setSearchName] = useState(initialSearchData.searchName || "");
    const [searchPhone, setSearchPhone] = useState(initialSearchData.searchPhone || "");
    const [searchBirth, setSearchBirth] = useState(initialSearchData.searchBirth || "");

    useEffect(() => {
        setSearchName(initialSearchData.searchName || "");
        setSearchPhone(initialSearchData.searchPhone || "");
        setSearchBirth(initialSearchData.searchBirth || "");
    }, [initialSearchData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSearch({ searchName, searchPhone, searchBirth });
    };

    const handleReset = () => {
        setSearchName("");
        setSearchPhone("");
        setSearchBirth("");
        onReset();
    };

    const handleDateChange = (date) => {
        setSearchBirth(date);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Form.Group as={Col} controlId="formPatientName">
                    <Form.Label column sm={4}>
                        환자명
                    </Form.Label>

                    <Form.Control
                        type="text"
                        name="searchName"
                        value={searchName}
                        onChange={(e) => setSearchName(e.target.value)}
                        placeholder="이름"
                        maxLength={50}
                    />
                </Form.Group>

                <Form.Group as={Col} controlId="formBirthDate">
                    <Form.Label column sm={5}>
                        생년월일
                    </Form.Label>
                    <MuiDatePicker maxDate={TODAY} value={searchBirth ? new Date(searchBirth) : null} onChange={handleDateChange} />
                </Form.Group>

                <Form.Group as={Col} controlId="formPhoneNumber">
                    <Form.Label column sm={6}>
                        핸드폰번호
                    </Form.Label>
                    <Form.Control
                        type="text"
                        name="searchPhone"
                        placeholder="휴대전화번호"
                        maxLength={11}
                        value={searchPhone}
                        onChange={(e) => setSearchPhone(e.target.value)}
                    />
                </Form.Group>
            </Row>

            <Row className="p-2">
                <Col className="p-2">
                    <Button variant="secondary" onClick={handleReset} className="me-2 w-30">
                        초기화
                    </Button>
                </Col>
                <Col xs lg="3" className="p-2">
                    <Button className="w-100" variant="primary" type="submit">
                        검색
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default SearchBar;
