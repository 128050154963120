import fetchData from "../apis/fetchData_v2";

/**
 * 환자 목록 조회
 * @param {Object} options - 검색 옵션
 * @param {number} options.page - 현재 페이지 번호
 * @param {number} options.pageSize - 페이지당 행 갯수
 * @param {Object} options.searchData - 검색 데이터
 * @param {string} options.searchData.listType - 목록 유형 ('ALL' 또는 'MY')
 * @param {string} [options.searchData.searchName] - 이름 검색어
 * @param {string} [options.searchData.searchPhone] - 전화번호 검색어
 * @param {string} [options.searchData.searchBirth] - 생년월일 검색어
 * @returns {Promise<Object>} 환자 목록 데이터
 */
export const getPatientsList = ({ page, pageLimit, searchData }) => {
    return fetchData({
        method: "POST",
        endpoint: "/v1/management/patient/list",
        payload: {
            page,
            pageLimit,
            search: {
                listType: searchData.listType,
                searchName: searchData.searchName || null,
                searchPhone: searchData.searchPhone || null,
                searchBirth: searchData.searchBirth || null,
            },
        },
    })
        .then((response) => response)
        .catch((error) => {
            console.error("Failed to fetch patients:", error);
            throw new Error(`Failed to fetch patients: ${error.message}`);
        });
};
