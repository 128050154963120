import { atom } from "recoil";

const currentDate = atom({
    key: "currentDate",
    default: "",
});

const currentDuration = atom({
    key: "currentDuration",
    default: "day",
});

export { currentDate, currentDuration };
