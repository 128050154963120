import fetchData from "../apis/fetchData_v2";

/**
 * 서브도메인 유효성 검사
 * @param {string} domain - 검사할 도메인
 * @returns {Promise<{mode: boolean, msg: string}>} 도메인의 유효성 여부와 메시지
 */
export const checkDomainValidity = async (domain) => {
    try {
        const response = await fetchData({
            method: "POST",
            endpoint: "/v1/auth/verify/domain",
            payload: { domain },
        });
        return response;
    } catch (error) {
        console.error("Failed to check domain validity:", error);
        throw new Error(`Failed to check domain validity: ${error.message}`);
    }
};
