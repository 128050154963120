import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import { useEffect } from "react";
import { Row } from "react-bootstrap";
import HospitalInfoTable from "../HospitalInfoTable";
import OperatorListTable from "./OperatorListTable";

//왜지
const OperatorSetting = () => {
    const { data: operatorInfo, mutate: operatorInfoMutate } = useMutation({
        mutationFn: () => fetchData("GET", "/v1/setting/operator/list"),
    });

    useEffect(() => {
        operatorInfoMutate();
    }, [operatorInfoMutate]);

    return (
        <div className="pb-5">
            <Row className="g-2">
                <div className="mb-2">
                    <h2>운영자 관리</h2>
                </div>
                <hr className="bg-body-secondary mb-3" />
            </Row>

            <div className="order-1 order-xl-0 col-xl-12 col-12">
                <div className="mb-4 shadow-none border overflow-visible card">
                    <div className="p-0 card-body">
                        <div className="p-4">
                            {operatorInfo && (
                                <>
                                    <div className="mb-8">
                                        <HospitalInfoTable hospitalInfo={operatorInfo.data.hospitalInfo} />
                                    </div>
                                    <OperatorListTable operatorList={operatorInfo.data.operator} />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OperatorSetting;
