/**
 * 주어진 날짜와 기간에 따라 종료 날짜를 계산하는 함수입니다.
 *
 * @param {string} searchDate - 시작 날짜 (ISO 형식의 문자열)
 * @param {string} duration - 추가할 기간 (예: "weeks03", "weeks06", "weeks12")
 * @returns {string} - 계산된 종료 날짜 (ISO 형식의 문자열)
 */
const calculateEndDate = (searchDate, duration) => {
    const addWeeksToDate = (dateStr, weeks) => {
        const date = new Date(dateStr);
        date.setDate(date.getDate() + weeks * 7);
        return date.toISOString().split("T")[0];
    };

    switch (duration) {
        case "03":
            return addWeeksToDate(searchDate, 3);
        case "06":
            return addWeeksToDate(searchDate, 6);
        case "12":
            return addWeeksToDate(searchDate, 12);
        default:
            return searchDate;
    }
};

export default calculateEndDate;
