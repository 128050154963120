import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "sonner";
import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { validatePassword, validatePasswordMatch } from "utils/validateFormData";

const PasswordChangeModal = ({ show, onHide, uuid, role }) => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [capsLockOn, setCapsLockOn] = useState(false);
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [confirmPasswordFocused, setConfirmPasswordFocused] = useState(false);

    const handleCapsLock = useCallback((event) => {
        if (event && typeof event.getModifierState === "function") {
            setCapsLockOn(event.getModifierState("CapsLock"));
        }
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", handleCapsLock);
        window.addEventListener("keyup", handleCapsLock);

        return () => {
            window.removeEventListener("keydown", handleCapsLock);
            window.removeEventListener("keyup", handleCapsLock);
        };
    }, [handleCapsLock]);

    useEffect(() => {
        if (newPassword || confirmPassword) {
            validatePasswords();
        }
    }, [newPassword, confirmPassword]);

    const validatePasswords = () => {
        let newErrors = {};
        const passwordError = validatePassword(newPassword);
        if (passwordError) {
            newErrors.newPassword = passwordError;
        }
        const passwordMatchError = validatePasswordMatch(newPassword, confirmPassword);
        if (passwordMatchError) {
            newErrors.confirmPassword = passwordMatchError;
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const { mutate: updatePassword } = useMutation({
        mutationFn: (password) => fetchData("POST", "/v1/setting/update/password", { password, uuid, role }),
        onSuccess: () => {
            toast.success("비밀번호가 변경되었습니다.");
            onHide();
            resetForm();
        },
        onError: () => {
            toast.error("비밀번호 변경에 실패했습니다.");
        },
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validatePasswords()) {
            updatePassword(newPassword);
        } else {
            toast.error("비밀번호가 유효하지 않거나 일치하지 않습니다.");
        }
    };

    const resetForm = () => {
        setNewPassword("");
        setConfirmPassword("");
        setErrors({});
        setShowPassword(false);
        setShowConfirmPassword(false);
    };

    const iconClass = (error) =>
        error
            ? "position-absolute end-0 translate-middle-y me-6 cursor-pointer"
            : "position-absolute top-50 end-0 translate-middle-y me-2 cursor-pointer";

    const iconStyle = (error) => (error ? { top: "33%" } : {});

    return (
        <Modal
            show={show}
            onHide={() => {
                onHide();
                resetForm();
            }}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>비밀번호 변경</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>새 비밀번호</Form.Label>
                        <div className="position-relative">
                            <Form.Control
                                type={showPassword ? "text" : "password"}
                                placeholder="새 비밀번호 입력"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                isInvalid={!!errors.newPassword}
                                maxLength={100}
                                required
                                onFocus={() => setPasswordFocused(true)}
                                onBlur={() => setPasswordFocused(false)}
                            />
                            <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                className={iconClass(errors.newPassword)}
                                style={iconStyle(errors.newPassword)}
                                onClick={() => setShowPassword(!showPassword)}
                            />
                            <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
                        </div>
                        {passwordFocused && capsLockOn && <Form.Text className="text-warning">Caps Lock이 켜져 있습니다.</Form.Text>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>비밀번호 확인</Form.Label>
                        <div className="position-relative">
                            <Form.Control
                                type={showConfirmPassword ? "text" : "password"}
                                placeholder="비밀번호 확인"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                isInvalid={!!errors.confirmPassword}
                                maxLength={100}
                                required
                                onFocus={() => setConfirmPasswordFocused(true)}
                                onBlur={() => setConfirmPasswordFocused(false)}
                            />
                            <FontAwesomeIcon
                                icon={showConfirmPassword ? faEyeSlash : faEye}
                                className={iconClass(errors.confirmPassword)}
                                style={iconStyle(errors.confirmPassword)}
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            />
                            <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>
                        </div>
                        {confirmPasswordFocused && capsLockOn && <Form.Text className="text-warning">Caps Lock이 켜져 있습니다.</Form.Text>}
                    </Form.Group>
                    <div className="d-flex justify-content-end">
                        <Button
                            variant="secondary"
                            onClick={() => {
                                onHide();
                                resetForm();
                            }}
                            className="me-2"
                        >
                            취소
                        </Button>
                        <Button variant="primary" type="submit">
                            비밀번호 변경
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default PasswordChangeModal;
