import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import { useRef, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { ERROR_ID_CHECK, ERROR_ID_EMPTY, ERROR_PASSWORD_VALID, REGIST_CHECK, REGIST_FAIL, ERROR_PASSWORD_MATCH } from "constants/errorMessage";
import { ALPHA_NUMBER_PLACEHOLER, COMMENTS_PLACEHOLDER, NAME_PLACEHOLDER, NUMBER_ONLY_PLACEHOLDER } from "constants/placeholder";
import { ID_REGEX } from "constants/regex";
import useCheckDuplicateId from "hooks/useCheckDuplicateId";
import checkIsEmpty from "utils/checkIsEmpty";
import { validateFormData } from "utils/validateFormData";
import { toast } from "sonner";
import useConfirmDialog from "hooks/useConfirmDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const StaffRegisterForm = () => {
    const navigate = useNavigate();
    const idRef = useRef(null);
    const passwordRef = useRef(null);

    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        userId: "",
        userPassword: "",
        userPasswordCheck: "",
        comments: "",
    });

    const iconClass = (error) =>
        error
            ? "position-absolute end-0 translate-middle-y me-6 cursor-pointer"
            : "position-absolute top-50 end-0 translate-middle-y me-2 cursor-pointer";

    const iconStyle = (error) => (error ? { top: "33%" } : {});

    const [errors, setErrors] = useState({});
    const [isValidId, setIsValidId] = useState(false);
    const showConfirmDialog = useConfirmDialog();

    const [capsLockOn, setCapsLockOn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordCheck, setShowPasswordCheck] = useState(false);
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [passwordCheckFocused, setPasswordCheckFocused] = useState(false);

    const handleCapsLock = useCallback((event) => {
        if (event && typeof event.getModifierState === "function") {
            setCapsLockOn(event.getModifierState("CapsLock"));
        }
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", handleCapsLock);
        window.addEventListener("keyup", handleCapsLock);

        return () => {
            window.removeEventListener("keydown", handleCapsLock);
            window.removeEventListener("keyup", handleCapsLock);
        };
    }, [handleCapsLock]);

    const handlePasswordFocus = () => {
        setPasswordFocused(true);
    };

    const handlePasswordBlur = () => {
        setPasswordFocused(false);
    };

    const handlePasswordCheckFocus = () => {
        setPasswordCheckFocused(true);
    };

    const handlePasswordCheckBlur = () => {
        setPasswordCheckFocused(false);
    };

    // 아이디 중복확인 요청 mutation
    const checkDuplicateIdMutate = useCheckDuplicateId(formData.userId, setIsValidId, idRef, passwordRef);

    // 실무자 등록 api
    const { mutate: registMutate } = useMutation({
        mutationFn: (requestData) => fetchData("POST", "/v1/setting/staff/regist", requestData),
        onSuccess: () => {
            navigate("/staff-setting");
        },
        onError: () => toast.error(REGIST_FAIL),
    });

    const handleIdChange = (e) => {
        setIsValidId(false);
        handleChangeInput(e, "userId");
    };

    const handleChangeInput = (e, label) =>
        setFormData((prev) => {
            return { ...prev, [label]: e.target.value };
        });

    const handleCheckDuplicateId = (id) => {
        setIsValidId(false);

        if (!checkIsEmpty(id)) {
            toast.error(ERROR_ID_EMPTY);
            return;
        }

        if (!ID_REGEX.test(id)) {
            toast.error(ERROR_ID_CHECK);
            return;
        }

        checkDuplicateIdMutate(id);
    };

    // 실무자 등록 시 유효성 검사 및 api 전송
    const handleRegist = (e) => {
        e.preventDefault();

        const { name, phone, userPassword, userPasswordCheck } = formData;

        const validationResult = validateFormData({ name, phone, userPassword, userPasswordCheck }, isValidId, setErrors);

        if (!validationResult) return;

        showConfirmDialog({ text: REGIST_CHECK }, () => {
            registMutate({
                ...formData,
            });
        });
    };

    return (
        <Form onSubmit={handleRegist}>
            <Row className="mb-3">
                <Col>
                    <Form.Group as={Col} controlId="formName">
                        <Form.Label>
                            <h5>이름 *</h5>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={NAME_PLACEHOLDER}
                            value={formData.name}
                            onChange={(e) => handleChangeInput(e, "name")}
                            isInvalid={!!errors.name}
                            autoComplete="off"
                            maxLength={20}
                        />
                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group as={Col} controlId="formPhone">
                        <Form.Label>
                            <h5>연락처 *</h5>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={NUMBER_ONLY_PLACEHOLDER}
                            value={formData.phone}
                            onChange={(e) => handleChangeInput(e, "phone")}
                            isInvalid={!!errors.phone}
                            autoComplete="off"
                            maxLength={11}
                        />
                        <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>

            <Form.Group controlId="formUserId" className="mb-3">
                <Form.Label>
                    <h5>아이디 *</h5>
                </Form.Label>
                <Row>
                    <Col md={6}>
                        <Form.Control
                            type="text"
                            placeholder={ALPHA_NUMBER_PLACEHOLER}
                            value={formData.userId}
                            onChange={handleIdChange}
                            ref={idRef}
                            isInvalid={!!errors.userId}
                            autoComplete="off"
                            maxLength={20}
                        />
                    </Col>
                    <Col md={6}>
                        <Button variant="secondary" onClick={() => handleCheckDuplicateId(formData.userId)}>
                            아이디 중복확인
                        </Button>
                    </Col>
                </Row>
                <Form.Text className="text-muted">아이디 수정은 불가능합니다.</Form.Text>
            </Form.Group>

            <Row className="mb-3">
                <Col>
                    <Form.Group controlId="formPassword">
                        <Form.Label>
                            <h5>비밀번호 *</h5>
                        </Form.Label>
                        <div className="position-relative">
                            <Form.Control
                                type={showPassword ? "text" : "password"}
                                value={formData.userPassword}
                                onChange={(e) => handleChangeInput(e, "userPassword")}
                                placeholder={ALPHA_NUMBER_PLACEHOLER}
                                ref={passwordRef}
                                isInvalid={!!errors.userPassword}
                                onFocus={handlePasswordFocus}
                                onBlur={handlePasswordBlur}
                                maxLength={50}
                            />
                            <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                className={iconClass(errors.userPassword)}
                                style={iconStyle(errors.userPassword)}
                                onClick={() => setShowPassword(!showPassword)}
                            />
                            <Form.Control.Feedback type="invalid">{errors.userPassword}</Form.Control.Feedback>
                        </div>
                        {passwordFocused && capsLockOn && <Form.Text className="text-warning">Caps Lock이 켜져 있습니다.</Form.Text>}
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formPasswordCheck">
                        <Form.Label>
                            <h5>비밀번호 확인 *</h5>
                        </Form.Label>
                        <div className="position-relative">
                            <Form.Control
                                type={showPasswordCheck ? "text" : "password"}
                                value={formData.userPasswordCheck}
                                onChange={(e) => handleChangeInput(e, "userPasswordCheck")}
                                placeholder={ALPHA_NUMBER_PLACEHOLER}
                                isInvalid={!!errors.userPasswordCheck}
                                onFocus={handlePasswordCheckFocus}
                                onBlur={handlePasswordCheckBlur}
                                maxLength={50}
                            />
                            <FontAwesomeIcon
                                icon={showPasswordCheck ? faEyeSlash : faEye}
                                className={iconClass(errors.userPasswordCheck)}
                                style={iconStyle(errors.userPasswordCheck)}
                                onClick={() => setShowPasswordCheck(!showPasswordCheck)}
                            />
                            <Form.Control.Feedback type="invalid">{errors.userPasswordCheck}</Form.Control.Feedback>
                        </div>
                        {passwordCheckFocused && capsLockOn && <Form.Text className="text-warning">Caps Lock이 켜져 있습니다.</Form.Text>}
                    </Form.Group>
                </Col>
            </Row>

            <Form.Group controlId="formComments" className="mb-3">
                <Form.Label>
                    <h5> 비고</h5>
                </Form.Label>
                <Form.Control
                    as="textarea"
                    placeholder={COMMENTS_PLACEHOLDER}
                    value={formData.comments}
                    onChange={(e) => handleChangeInput(e, "comments")}
                    autoComplete="off"
                    rows={3}
                    maxLength={200}
                />
            </Form.Group>

            <div className="d-flex justify-content-end">
                <Button variant="secondary" onClick={() => navigate("/staff-setting")}>
                    뒤로가기
                </Button>
                <Button type="submit" className="ms-2">
                    등록하기
                </Button>
            </div>
        </Form>
    );
};

export default StaffRegisterForm;
