import classNames from "classnames";
import { useAppContext } from "providers/AppProvider";
import { Navbar } from "react-bootstrap";
import dtxLogo from "assets/logos/dtx_logo.svg";
import { useBreakpoints } from "providers/BreakpointsProvider";
import NavbarToggleButton from "./NavbarToggleButton";
import { Link } from "react-router-dom";
import getUserRoleLevel from "hooks/getUserRoleLevel";

const NavbarBrand = () => {
    const {
        config: { navbarTopShape, navbarPosition },
    } = useAppContext();
    const { breakpoints } = useBreakpoints();
    const hospitalInfo = JSON.parse(sessionStorage.getItem("infHsp") || "{}"); // 기관명 조회

    let path = getUserRoleLevel() === 0 ? "/operator-setting" : "/ett/patients";

    return (
        <>
            <div className="navbar-logo">
                {breakpoints.down("lg") && <NavbarToggleButton />}
                <Navbar.Brand
                    as={Link}
                    to={path}
                    className={classNames({
                        "me-1 me-sm-3": navbarTopShape === "slim" || navbarPosition === "horizontal",
                    })}
                >
                    {navbarTopShape === "slim" ? (
                        <>
                            {/* <img src={dtxLogo} alt="phoenix" width={20} /> 심장재활 디지털 치료제
                            <span className="text-body-highlight d-none d-sm-inline">관리시스템</span> */}
                            <img src={dtxLogo} alt="phoenix" width={20} /> 하루핏 카디오 관리시스템 <small>{hospitalInfo.name}</small>
                        </>
                    ) : (
                        <div className="d-flex align-items-center">
                            <img src={dtxLogo} alt="phoenix" width={27} />
                            <p className="logo-text ms-2 d-none d-sm-block">
                                {/* 심장재활 디지털 치료제 <small>관리시스템</small> */}
                                하루핏 카디오 관리시스템 <small>{hospitalInfo.name}</small>
                            </p>
                        </div>
                    )}
                </Navbar.Brand>
            </div>
        </>
    );
};

export default NavbarBrand;
