import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import OperatorInfo from "./MyOperatorInfo";
import HospitalInfoTable from "../HospitalInfoTable";

const MyOperatorDetail = () => {
    const token = sessionStorage.getItem("token");
    const decoded = jwtDecode(token);
    const operatorUUID = decoded.uuid;

    const { data: operatorDetail, mutate: operatorDetailMutate } = useMutation({
        mutationFn: (requestData) => fetchData("POST", "/v1/setting/operator/detail", requestData),
    });

    const { data: staffInfo, mutate: staffInfoMutate } = useMutation({
        mutationFn: () => fetchData("GET", "/v1/setting/staff/list"),
    });

    useEffect(() => {
        staffInfoMutate();
    }, [staffInfoMutate]);

    useEffect(() => {
        operatorDetailMutate({ operatorUUID });
    }, [operatorDetailMutate, operatorUUID]);

    return (
        <>
            <div className="pb-5">
                <Row className="g-2">
                    <div className="mb-2">
                        <h2>내정보 관리</h2>
                    </div>
                    <hr className="bg-body-secondary mb-3" />
                </Row>
                <div className="order-1 order-xl-0 col-xl-12 col-12">
                    <div className="mb-4 shadow-none border overflow-visible card">
                        <div className="p-0 card-body">
                            <div className="p-4">
                                {staffInfo && staffInfo.data && staffInfo.data.hospitalInfo && (
                                    <div className="mb-8">
                                        <HospitalInfoTable hospitalInfo={staffInfo.data.hospitalInfo} />
                                    </div>
                                )}
                                <Row className="g-2">
                                    <div className="mb-2">
                                        <h3>기본 정보</h3>
                                    </div>
                                    <hr className="bg-body-secondary mb-3" />
                                </Row>
                                <Row>
                                    <Col>{operatorDetail && <OperatorInfo operatorDetail={operatorDetail} operatorUUID={operatorUUID} />}</Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyOperatorDetail;
