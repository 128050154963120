import MuiDatePicker from "components/base/MuiDatePicker";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { currentDate } from "store/atoms";
import { useEffect, useState } from "react";
import Sts30Sec from "./Sts30Sec";
import Walking6Min from "./Walking6Min";
import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";

const EvaluationResultContainer = () => {
    const patientUUID = sessionStorage.getItem("patientUUID");
    const patientUID = sessionStorage.getItem("patientUID");
    const [searchDate, setSearchDate] = useState(sessionStorage.getItem("workoutDate"));
    const navigate = useNavigate();

    const { mutate: evaluationMutate, data: evaluationResult } = useMutation({
        mutationFn: ({ patientUID, searchDate }) => fetchData("POST", "/v1/monitoring/evaluation/day", { patientUID, searchDate }),
    });

    useEffect(() => {
        evaluationMutate({ patientUID, searchDate });
    }, [searchDate]);

    return (
        evaluationResult && (
            <>
                <div className="w-50">
                    <MuiDatePicker
                        onChange={(value) => {
                            setSearchDate(value);
                        }}
                        value={searchDate}
                    />
                </div>
                <Row>
                    <Col md={12}>
                        <Walking6Min walking6MinData={evaluationResult.data?.walking6Min} />
                    </Col>
                    <Col md={12}>
                        <Sts30Sec sts30SecData={evaluationResult.data?.sts30Sec} />
                    </Col>
                </Row>
            </>
        )
    );
};

export default EvaluationResultContainer;
